import { Button, capitalize, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// @ts-ignore
import de from "./terms/de_terms.pdf";
// @ts-ignore
import en from "./terms/en_terms.pdf";

const TermsOfUse = () => {
  const { t } = useTranslation();

  return (
    <Stack direction="column" rowGap={1}>
      <Button component={Link} to={de} target="_blank">
        DE - {capitalize(t("auth.signup.termsOfUse"))}
      </Button>
      <Button component={Link} to={en} target="_blank">
        EN - {capitalize(t("auth.signup.termsOfUse"))}
      </Button>
    </Stack>
  );
};

export default TermsOfUse;
