import { createEntityAdapter } from "@reduxjs/toolkit";
import { CoffeeMachine } from "../coffe-machines";
import { User } from "../users";

export enum RecurringTopUpInterval {
  Daily = "daily",
  Weekly = "weekly",
  Monthly = "monthly",
  Custom = "custom",
}

export enum RecurringTopUpIntervalRangeType {
  Day = "day",
  Week = "week",
  Month = "month"
}

export enum RecurringTopUpInstant {
  Start = "start",
  End = "end",
}

export interface UserGroup {
  id: string;
  name: string;
  coffeeMachines: CoffeeMachine[];
  users: User[];
  coffeeMenuId?: string;
  recurringTopUpEnabled: boolean;
  recurringTopUpInstant: RecurringTopUpInterval | null;
  recurringTopUpInterval: RecurringTopUpInterval | null;
  recurringTopUpIntervalRange?: number | null;
  recurringTopUpIntervalRangeType?: string | null;
  recurringTopUpIntervalAmount: number | null;
  recurringTopUpStart?: Date | null;
  recurringTopUpLimit?: number | null;
}

export const userGroupsAdapter = createEntityAdapter<UserGroup>({
  selectId: (user) => user.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});
