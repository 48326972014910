import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DateRangePicker from "../../../components/DateRange";

interface DateRangeFilterProps {
  from: Date | null;
  to: Date | null;
  setFrom: (date?: Date | null) => void;
  setTo: (date?: Date | null) => void;
  timezone: string;
}

function DateRangeFilter({ from, to, setFrom, setTo, timezone }: DateRangeFilterProps) {
  const { t } = useTranslation();
  return (
    <Stack>
      <Typography variant="h4" marginBottom={2}>
        {t("usermanagement.filters.dateFilterTitle")}
      </Typography>

      <DateRangePicker from={from} to={to} setFrom={setFrom} setTo={setTo} timezone={timezone} />
    </Stack>
  );
}

export default DateRangeFilter;
