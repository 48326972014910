import {
  AddCircle,
  GridViewRounded,
  Person,
  RestartAlt,
  ViewListRounded,
} from "@mui/icons-material";
import {
  Grid,
  Stack,
  Typography,
  styled,
  Box,
  Button,
  Link,
  useMediaQuery,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Tooltip,
} from "@mui/material";
import { Link as BaseLink, useHistory } from "react-router-dom";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import Amount from "../../components/Amount";
import { DataGrid } from "../../components/DataGrid";
import { useGetCoffeeMachinesQuery } from "../../state/services/api";
import { capitalize } from "../../utils/string";
import TileGrid from "../../components/TileGrid";
import { useCallback, useMemo, useState } from "react";
import {
  CoffeeMachine,
  selectFilter,
  setFilter,
} from "../../state/coffe-machines";
import Tile from "../../components/Tile";

import { useSelector } from "react-redux";
import { getAll } from "../../utils/api";

import EntityDialog from "../../components/EntityDialog";
import CoffeeMachineTreeSelect from "../../components/CoffeeMachineTreeSelect";
import { useEntityDialog } from "../../hooks/dialog";
import muiTheme from "../../theme";
import { selectCurrentUser } from "../../state/auth";

const ButtonIcon = styled("div", {
  shouldForwardProp: (prop) => prop !== "active",
})<{ active?: boolean }>`
  ${({ active, theme }) => active && `color: ${theme.palette.primary.main};`}

  &:hover {
    color: ${({ theme }) => theme.palette.primary.dark};
    cursor: pointer;
  }
`;

const MachineImage = styled("img")`
  width: 100%;
  height: auto;
  object-fit: contain;
  mix-blend-mode: multiply;
`;

const MachineTile = styled(Tile)`
  cursor: pointer;
  overflow-wrap: anywhere;

  &:hover .MuiPaper-root {
    background: ${({ theme }) => theme.palette.primary.light};
  }
`;

export const ADD_MACHINE_DIALOG_NAME = "add-machine";

const AddMachineDialog = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const steps = Array.from({ length: 5 }, (_, i) => i + 1);
  return (
    <EntityDialog
      name={ADD_MACHINE_DIALOG_NAME}
      title={t("coffeeMachines.addMachineDialog.title")}
      okButton={
        <Button startIcon={<RestartAlt />} onClick={handleReset}>
          {capitalize(t("coffeeMachines.addMachineDialog.restart"))}
        </Button>
      }
      className="add-coffee-machine-dialog"
    >
      <Box>
        <Stepper orientation="vertical" activeStep={activeStep}>
          {steps.map((step, index) => (
            <Step key={step}>
              <StepLabel
                optional={
                  index === steps.length - 1 ? (
                    <Typography variant="caption">
                      {t("coffeeMachines.addMachineDialog.lastStepLabel")}
                    </Typography>
                  ) : null
                }
              >
                {t(
                  `coffeeMachines.addMachineDialog.addMachineInstructions.step${step}.label`
                )}
              </StepLabel>
              <StepContent>
                <Typography>
                  {t(
                    `coffeeMachines.addMachineDialog.addMachineInstructions.step${step}.description`
                  )}
                </Typography>
                <Box sx={{ mb: 2 }}>
                  <Stack direction="row">
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {capitalize(t("coffeeMachines.addMachineDialog.back"))}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {index === steps.length - 1
                        ? capitalize(
                            t("coffeeMachines.addMachineDialog.finish")
                          )
                        : capitalize(
                            t("coffeeMachines.addMachineDialog.continue")
                          )}
                    </Button>
                  </Stack>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </EntityDialog>
  );
};

const CoffeeMachineListing = () => {
  const { t } = useTranslation();

  const smScreen = useMediaQuery<typeof muiTheme>((theme) =>
    theme.breakpoints.up("sm")
  );

  const user = useSelector(selectCurrentUser);

  const isMachineOwner = useCallback(
    (machine: CoffeeMachine) => machine.createdByUserId === user?.id,
    [user]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "owner",
        valueGetter: (params: GridRenderCellParams<any, CoffeeMachine>) =>
          params.row.createdByUser.email,
        cellClassName: "owner",
        headerName: capitalize(t("coffeeMachines.property.owner")),
        minWidth: 100,
        flex: 1,
      },
      {
        field: "model",
        cellClassName: "model",
        headerName: capitalize(t("coffeeMachines.property.model")),
        minWidth: 130,
        flex: 1,
      },
      {
        field: "nameHuman",
        cellClassName: "name",
        headerName: capitalize(t("coffeeMachines.property.name")),
        minWidth: smScreen ? 130 : 0,
        flex: 1,
      },
      {
        field: "serial",
        cellClassName: "serial",
        headerName: capitalize(t("coffeeMachines.property.serial")),
        minWidth: 130,
        headerAlign: "center",
        align: "center",
        flex: 1,
      },
      {
        field: "location",
        valueGetter: (params) => params.row.location.name,
        cellClassName: "location",
        headerName: capitalize(t("location")),
        minWidth: 130,
        headerAlign: "center",
        align: "center",
        flex: 1,
      },
      {
        field: "preselectMode",
        cellClassName: "preselectMode",
        valueFormatter: (params: GridValueFormatterParams) =>
          t(`coffeeMachines.preselectMode.${params.value}`),
        headerName: capitalize(t("coffeeMachines.property.preselectMode")),
        minWidth: 130,
        headerAlign: "center",
        align: "center",
        flex: 1,
      },
      {
        field: "detail",
        cellClassName: "details",
        headerName: capitalize(t("details")),
        minWidth: smScreen ? 160 : 0,
        headerAlign: "right",
        align: "right",
        flex: 1,
        renderCell: (params: GridRenderCellParams<any, void>) => {
          return (
            <Link component={BaseLink} to={`/coffee-machines/${params.row.id}`}>
              <Typography
                fontWeight="bold"
                sx={{ textDecoration: "underline" }}
              >
                {capitalize(t("show"))}
              </Typography>
            </Link>
          );
        },
      },
    ],
    [t, smScreen]
  );

  const visibleColumns = useMemo(
    () => ({
      owner: true,
      model: smScreen,
      name: true,
      serial: smScreen,
      location: smScreen,
      detail: true,
    }),
    [smScreen]
  );

  const filter = useSelector(selectFilter);

  const { data: machinesState, isLoading } = useGetCoffeeMachinesQuery(filter);
  const ids = machinesState?.ids || [];
  const machines = machinesState && getAll(machinesState);

  const [page, setPage] = useState(0);

  const [viewType, setViewType] = useState("grid");

  const history = useHistory();

  const { open: openAddMachineDialog } = useEntityDialog({
    name: ADD_MACHINE_DIALOG_NAME,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} mb={4}>
        <Stack
          direction="column"
          flexDirection={{ md: "row" }}
          justifyContent={{ md: "space-between" }}
          alignItems="flex-start"
        >
          <Box>
            <Typography variant="h1">{t("coffeeMachines.title")}</Typography>
            <Typography paragraph>{t("coffeeMachines.subTitle")}</Typography>
          </Box>
          <Button
            sx={{
              px: { xs: 0, sm: 10 },
              py: 1,
              width: { xs: "100%", sm: "auto " },
            }}
            startIcon={<AddCircle />}
            onClick={openAddMachineDialog}
            className="add-coffee-machine"
          >
            {t("coffeeMachines.addMachineButtonText")}
          </Button>
          <AddMachineDialog />
        </Stack>
      </Grid>

      {viewType === "grid" && (
        <Grid item xs={12} mb={2}>
          <Amount
            amount={machines?.length ?? 0}
            text={t("coffeeMachines.numberOfMachines")}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Stack
          direction="column"
          flexDirection={{ sm: "row" }}
          justifyContent={{ sm: "space-between" }}
          alignItems={{ sm: "flex-end" }}
        >
          <Stack
            direction="row"
            align-items="center"
            height="fit-content"
            mb={{ xs: 4, sm: 0 }}
          >
            <ButtonIcon
              active={viewType === "grid"}
              onClick={() => setViewType("grid")}
            >
              <GridViewRounded />
            </ButtonIcon>
            <ButtonIcon
              active={viewType === "list"}
              onClick={() => setViewType("list")}
            >
              <ViewListRounded />
            </ButtonIcon>
          </Stack>
          <CoffeeMachineTreeSelect
            selectFilter={selectFilter}
            setFilter={setFilter}
            inputProps={{ sx: { width: "100%" } }}
          />
        </Stack>
      </Grid>

      {viewType === "list" ? (
        <Grid item xs={12}>
          <DataGrid
            head={
              <Amount
                amount={machines?.length ?? 0}
                text={t("coffeeMachines.numberOfMachines")}
              />
            }
            rows={machines ?? []}
            columns={columns}
            paginationModel={{ pageSize: 10, page }}
            onPaginationModelChange={(pagination) => setPage(pagination.page)}
            columnHeaderHeight={32 * 1.5}
            initialState={{
              columns: {
                columnVisibilityModel: visibleColumns,
              },
            }}
          />
        </Grid>
      ) : (
        <TileGrid item busy={isLoading} ids={ids}>
          {machines?.map((machine: CoffeeMachine) => (
            <MachineTile
              id={machine.id}
              title={machine.nameHuman ?? machine.model}
              key={machine.id}
              onClick={() => history.push(`/coffee-machines/${machine.id}`)}
              icon={
                isMachineOwner(machine) ? (
                  <Tooltip arrow title={t("coffeeMachine.ownerTooltip")}>
                    <Person />
                  </Tooltip>
                ) : null
              }
              className="coffee-machine-tile"
              header={
                <MachineImage
                  sx={{ flexGrow: 1, mb: 2 }}
                  src={`${process.env.REACT_APP_API_BASE_URL}${machine?.picture}`}
                  alt={machine.model}
                />
              }
              xs={12}
              sm={6}
              md={6}
              lg={3}
            >
              <Box
                display="grid"
                sx={{
                  gridTemplateColumns: "1fr 1fr",
                  gridColumnGap: "0.8rem",
                  overflowWrap: "anywhere",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {capitalize(t("coffeeMachines.property.model"))}
                </Typography>
                <Typography>{machine.model}</Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {capitalize(t("coffeeMachines.property.serial"))}
                </Typography>
                <Typography>{machine.serial}</Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {capitalize(t("location"))}
                </Typography>
                <Typography>{machine.location.name}</Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {capitalize(t("coffeeMachines.property.name"))}
                </Typography>
                <Typography>{machine.nameHuman}</Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {capitalize(t("coffeeMachines.property.preselectMode"))}
                </Typography>
                <Typography>
                  {t(`coffeeMachines.preselectMode.${machine.preselectMode}`)}
                </Typography>
              </Box>
            </MachineTile>
          ))}
        </TileGrid>
      )}
    </Grid>
  );
};

export default CoffeeMachineListing;
