import { ReactElement, JSXElementConstructor } from "react";
import { BaseSchema } from "yup";
import { AnyObject } from "yup/lib/types";
import { PaymentProvider } from "./interface";
import { requiredName } from "../../../utils/i18n";
import * as Yup from "yup";
import { TFunction } from "i18next";
import { OptionalObjectSchema, ObjectShape, TypeOfShape } from "yup/lib/object";
import paygateFields from "../../../page/setup/PaymentFields/PaygateFields";
import { PaygatePaymentOptions } from "..";

export default class PaygateProvider implements PaymentProvider {
  type = "paygate";

  options = ["paygateId", "password"] as Array<
    keyof PaygatePaymentOptions
  >;

  getOptionSchema(
    schema: OptionalObjectSchema<
      ObjectShape,
      AnyObject,
      TypeOfShape<ObjectShape>
    >,
    t: TFunction
  ): BaseSchema<any, AnyObject, any> {
    return schema.shape({
      paygateId: Yup.string().required(
        requiredName(t, "setup.payment.paygate.paygateId")
      ),
      password: Yup.string().required(
        requiredName(t, "setup.payment.paygate.password")
      ),
    });
  }

  fields(props: any): ReactElement<any, string | JSXElementConstructor<any>> {
    return paygateFields(props);
  }
}
