import {
  styled,
  Button,
  Box,
  Modal,
  ModalProps,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const StyledDialog = styled(Modal)`
  position: absolute;
`;

const ScrollContainer = styled(Box)`
  position: fixed;
  width: 100%;
  height: calc(100vh - ${({ theme }) => theme.spacing(7)});
  left: 0;
  overscroll-behavior: contain;
  padding-bottom: 100px;
  overflow: auto;

  ${(props) => props.theme.breakpoints.up("sm")} {
    position: absolute;
  }
`;

const DialogBox = styled(Paper)`
  position: relative;
  width: fit-content;
  height: fit-content;
  margin: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(2)};
  box-shadow: 0 3px 6px #aaaaaa;
  background: white;
  left: calc(50% - ${({ theme }) => theme.spacing(4)});
  transform: translateX(-50%);
`;

export interface DialogProps extends ModalProps {
  title: string;
  subTitle?: string;
  removeButton?: ReactElement;
  okButton?: ReactElement;
  preventBack?: boolean;
  maxWidth?: number;
  onClose?: () => void;
}

const Dialog = ({
  children,
  title,
  subTitle,
  onClose,
  removeButton,
  okButton,
  className,
  maxWidth,
  container,
  preventBack,
  ...props
}: DialogProps) => {
  const { t } = useTranslation();

  const [offset, setOffset] = useState(window.lockedOn);

  useEffect(() => {
    if (props.open) {
      if (window.lockedOn > -1) return;
      const main = document.querySelector("#root") as HTMLElement | undefined;

      window.lockedOn = window.pageYOffset;

      if (main) {
        main.style.width = `100%`;
        main.style.top = `${-window.lockedOn}px`;
        main.style.position = "fixed";
      }
    } else {
      if (window.lockedOn === -1) return;
      const main = document.querySelector("#root");

      if (main) {
        // @ts-ignore
        main.style.width = null;
        // @ts-ignore
        main.style.position = null;
        // @ts-ignore
        main.style.top = null;
      }

      document.documentElement.scrollTop = window.lockedOn;
      window.lockedOn = -1;
    }

    setOffset(window.lockedOn);
  }, [props.open]);

  return (
    <StyledDialog
      {...props}
      sx={{ top: offset > 0 ? offset : 0 }}
      onClose={onClose}
      container={
        container ?? (() => document.getElementById("content-container"))
      }
    >
      <ScrollContainer>
        <DialogBox
          className={className}
          sx={{ maxWidth: maxWidth ?? undefined }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h2">{title}</Typography>
              {subTitle && <Typography>{subTitle}</Typography>}
            </Grid>
            <Grid item xs={12} sx={{ width: "min-content" }}>
              {children}
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Box gap={2} display="flex">
                {!preventBack && (
                  <Button startIcon={<ChevronLeft />} onClick={onClose}>
                    {t("back")}
                  </Button>
                )}
                {removeButton}
                {okButton}
              </Box>
            </Grid>
          </Grid>
        </DialogBox>
      </ScrollContainer>
    </StyledDialog>
  );
};

export default Dialog;
