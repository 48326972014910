import ReactMarkdown from "react-markdown";
import rehypeAttrs from "rehype-attr";
import rehypeRaw from "rehype-raw";
import LinkRenderer from "./LinkRenderer";
import { styled } from "@mui/material";

const NoSpacingMarkdownStyled = styled(ReactMarkdown)`
  p {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
`;

const NoSpacingMarkdown = ({
  children,
  ...props
}: Parameters<typeof NoSpacingMarkdownStyled>[0]) => {
  return (
    <NoSpacingMarkdownStyled
      linkTarget="_blank"
      components={{ a: LinkRenderer }}
      rehypePlugins={[rehypeRaw, [rehypeAttrs, { properties: "attr" }]]}
      {...props}
    >
      {children}
    </NoSpacingMarkdownStyled>
  );
};

export default NoSpacingMarkdown;
