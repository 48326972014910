import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import { useParams } from "react-router";
import { capitalize } from "../../../utils/string";
import { useTranslation } from "react-i18next";
import { useGetCoffeeMachineQuery } from "../../../state/services/api";

interface MatchParams {
  machineId: string;
}

const MachineImage = styled("img")`
  width: 100%;
  height: auto;
  object-fit: contain;
  mix-blend-mode: multiply;
`;

const Overview = () => {
  const { t } = useTranslation();

  const { machineId } = useParams<MatchParams>();

  const { data: machine, isLoading: isLoadingMachine } =
    useGetCoffeeMachineQuery(machineId);

  return (
    <Grid item container xs={12}>
      <Grid item xs={12}>
        <Paper>
          <Grid sx={{ pt: { xs: 2, sm: 4 }, p: 2 }} container>
            {!machine || isLoadingMachine ? (
              <CircularProgress
                size={20}
                color="primary"
                sx={{ margin: "0 auto", mt: -2 }}
              />
            ) : (
              <>
                <Grid item xs={12} sm={8} md={6} lg={4}>
                  <MachineImage
                    src={`${process.env.REACT_APP_API_BASE_URL}${machine?.picture}`}
                  />
                </Grid>

                <Grid item xs="auto">
                  <Box
                    display="grid"
                    sx={{ gridTemplateColumns: "1fr 1fr", gridGap: "10px" }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      {capitalize(t("coffeeMachines.property.model"))}
                    </Typography>
                    <Typography>{machine?.model}</Typography>

                    <Typography sx={{ fontWeight: "bold" }}>
                      {capitalize(t("coffeeMachines.property.name"))}
                    </Typography>
                    <Typography> {machine?.nameHuman} </Typography>

                    <Typography sx={{ fontWeight: "bold" }}>
                      {capitalize(t("location"))}
                    </Typography>
                    <Typography>{machine?.location.name}</Typography>

                    <Typography sx={{ fontWeight: "bold" }}>
                      {capitalize(t("coffeeMachines.property.pin"))}
                    </Typography>
                    <Typography> {machine?.pin} </Typography>

                    <Typography sx={{ fontWeight: "bold" }}>
                      {capitalize(t("coffeeMachines.property.maintenancePin"))}
                    </Typography>
                    <Typography> {machine?.maintenancePin} </Typography>

                    <Typography sx={{ fontWeight: "bold" }}>
                      {capitalize(t("coffeeMachines.property.serial"))}
                    </Typography>
                    <Typography> {machine?.serial} </Typography>

                    <Typography sx={{ fontWeight: "bold" }}>
                      {capitalize(t("coffeeMachines.property.preselectMode"))}
                    </Typography>
                    <Typography>
                      {t(
                        `coffeeMachines.preselectMode.${machine.preselectMode}`
                      )}
                    </Typography>

                    <Typography sx={{ fontWeight: "bold" }}>
                      {capitalize(t("coffeeMachines.property.owner"))}
                    </Typography>
                    <Typography>
                      {machine.createdByUser?.email}
                    </Typography>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Overview;
