import { Button, capitalize, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import TextInput from "./form/TextInput";
import { Save } from "@mui/icons-material";
import { useCallback } from "react";
import {
  useCreateCompanyMutation,
  useGetCurrenciesQuery,
} from "../state/services/api";
import * as Yup from "yup";
import MultiSelect from "../components/form/MultiSelect";
import { TFunction } from "i18next";
import { regionOptions } from "../state/company/company";
import { useAlert } from "./alerts/AlertContext";
import { Company } from "../state/auth";

const CompanySchema = (t: TFunction) =>
  Yup.object().shape({
    name: Yup.string().required(t("required", { field: "name" })),
    currency: Yup.string().required(),
    region: Yup.string().oneOf(regionOptions(t).map((option) => option.value)),
    defaultLocationName: Yup.string().required(
      t("required", { field: "location" })
    ),
  });

interface CreateCompanyProps {
  onCreate?: (company: Company) => void;
}

const createCurrencyOptions = (currencies: string[]) =>
  currencies.map((currency) => ({ label: currency, value: currency }));

const CreateCompany = ({ onCreate }: CreateCompanyProps) => {
  const { t } = useTranslation();

  const { data: currencies, isSuccess: currenciesLoaded } =
    useGetCurrenciesQuery();

  const [createCompany, { isLoading: isSaving }] = useCreateCompanyMutation();

  const alert = useAlert();

  const submit = useCallback(
    async (values: typeof initialValues) => {
      try {
        const company = await createCompany({
          ...values,
          region: values.region || null,
        }).unwrap();

        alert({
          severity: "success",
          autoHideDuration: 3000,
          content: t("setup.company.saveSuccess"),
        });

        onCreate?.(company)
      } catch (e) {
        alert({
          severity: "error",
          autoHideDuration: 3000,
          content: t("setup.company.saveFailure"),
        });
      }
    },
    [alert, createCompany, onCreate, t]
  );

  const initialValues = {
    name: "",
    currency: "CHF",
    region: "other",
    defaultLocationName: "",
  };

  if (!currenciesLoaded) {
    return <CircularProgress size={20} color="white" />;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CompanySchema(t)}
      enableReinitialize={true}
      onSubmit={submit}
    >
      <Form>
        <TextInput
          name="name"
          type="text"
          title={capitalize(t("name")) + ":"}
        />
        <TextInput
          name="defaultLocationName"
          title={capitalize(t("location")) + ":"}
          type="text"
        />
        <MultiSelect
          name="currency"
          title={capitalize(t("currency")) + ":"}
          options={createCurrencyOptions(currencies!)}
          helperText={t("required", { field: "currency" })}
          required
        />
        <MultiSelect
          name="region"
          title={capitalize(t("region")) + ":"}
          options={regionOptions(t)}
          required
        />
        <Button
          type="submit"
          variant="contained"
          startIcon={<Save />}
          sx={{ mt: 2 }}
        >
          {isSaving ? <CircularProgress size={20} color="white" /> : t("save")}
        </Button>
      </Form>
    </Formik>
  );
};

export default CreateCompany;
