import {
  EndOfWeekOptions,
  StartOfWeekOptions,
  endOfDay as baseEndOfDay,
  endOfMonth as baseEndOfMonth,
  endOfWeek as baseEndOfWeek,
  endOfYear as baseEndOfYear,
  startOfDay as baseStartOfDay,
  startOfMonth as baseStartOfMonth,
  startOfWeek as baseStartOfWeek,
  startOfYear as baseStartOfYear,
} from "date-fns";
import { fromZonedTime, toZonedTime } from "date-fns-tz";

const timezoneAdjusted = (
  date: Date,
  fn: (date: Date) => Date,
  tz?: string
) => {
  const zoned = toZonedTime(date, tz ?? "Etc/UTC");
  const adjusted = fn(zoned);
  return fromZonedTime(adjusted, tz ?? "Etc/UTC");
};

export function startOfDay(date: Date, tz?: string) {
  return timezoneAdjusted(date, baseStartOfDay, tz);
}

export function endOfDay(date: Date, tz?: string) {
  return timezoneAdjusted(date, baseEndOfDay, tz);
}

export function startOfWeek(
  date: Date,
  tz?: string,
  options?: StartOfWeekOptions
) {
  return timezoneAdjusted(date, (date) => baseStartOfWeek(date, options), tz);
}

export function endOfWeek(date: Date, tz?: string, options?: EndOfWeekOptions) {
  return timezoneAdjusted(date, (date) => baseEndOfWeek(date, options), tz);
}

export function startOfMonth(date: Date, tz?: string) {
  return timezoneAdjusted(date, baseStartOfMonth, tz);
}

export function endOfMonth(date: Date, tz?: string) {
  return timezoneAdjusted(date, baseEndOfMonth, tz);
}

export function startOfYear(date: Date, tz?: string) {
  return timezoneAdjusted(date, baseStartOfYear, tz);
}

export function endOfYear(date: Date, tz?: string) {
  return timezoneAdjusted(date, baseEndOfYear, tz);
}

export function startOfToday(tz?: string) {
  return startOfDay(new Date(), tz);
}
