import { useTranslation } from "react-i18next";
import EntityDialog from "../../components/EntityDialog";
import { CREDIT_LOG_DIALOG_NAME } from "./modals";
import { useUserGetCreditLogsQuery } from "../../state/services/api";
import { useEntityDialog } from "../../hooks/dialog";
import { GridColDef } from "@mui/x-data-grid";
import { DataGrid } from "../../components/DataGrid";
import { Box } from "@mui/material";
import { format } from "date-fns";

const CreditLogModal = () => {
  const { t } = useTranslation();

  const { entityId: userId } = useEntityDialog({
    name: CREDIT_LOG_DIALOG_NAME,
  });

  const { data: creditLogs } = useUserGetCreditLogsQuery(
    { userId: userId! },
    { skip: !userId }
  );

  const columns: GridColDef[] = [
    {
      field: "type",
      headerName: t("usermanagement.creditLogModal.column.type"),
      valueFormatter: ({ value }) => t(`credit.topUpType.${value}`),
      minWidth: 150,
    },
    {
      field: "topUpAmount",
      headerName: t("usermanagement.creditLogModal.column.amount"),
      minWidth: 200,
    },
    {
      field: "createdAt",
      headerName: t("usermanagement.creditLogModal.column.date"),
      align: "right",
      headerAlign: "right",
      flex: 1,
      valueFormatter: ({ value }) => format(new Date(value), "dd.MM.yyyy HH:mm:ss"),
    },
  ];

  return (
    <EntityDialog
      name={CREDIT_LOG_DIALOG_NAME}
      title={t("usermanagement.creditLogModal.title")}
      className="credit-log-modal"
      maxWidth={800}
    >
      <Box>
        <DataGrid
          tableOnly
          columns={columns}
          rows={creditLogs ?? []}
        />
      </Box>
    </EntityDialog>
  );
};

export default CreditLogModal;
