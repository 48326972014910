import { Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import TextInput from "../../../components/form/TextInput";
import { MolliePayment } from "../../../state/payments";

const MollieFields = ({ values }: { values: Omit<MolliePayment, "id"> }) => {
  const { t } = useTranslation();
  return (
    <>
      <Paper sx={{ p: 2, mt: 2 }}>
        <Typography variant="h2">
          {t("setup.payment.mollie.generalTitle")}
        </Typography>

        <TextInput
          key="options.apiKey"
          name="options.apiKey"
          type="password"
          title={t("setup.payment.mollie.apiKey") + ":"}
        />
      </Paper>
    </>
  );
};

export default MollieFields;
