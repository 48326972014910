import { CSSProperties } from "react";

export interface VimeoPlayerProps {
  url: string;
}
export const VimeoPlayer = ({ url }: VimeoPlayerProps) => {
  const vimeoWrapperStyles: CSSProperties = {
    padding: "56.25% 0 0 0",
    position: "relative",
    width: "100%",
  };
  const vimeoIframeStyles: CSSProperties = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  };

  return (
    <div style={vimeoWrapperStyles}>
      <iframe src={url}
              frameBorder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              style={vimeoIframeStyles} title="Coffee_Menus_DE"></iframe>
    </div>
  );
};
