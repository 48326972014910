import { Save } from "@mui/icons-material";
import { Button, capitalize } from "@mui/material";
import EntityDialog from "./EntityDialog";
import { useEntityDialog } from "../hooks/dialog";
import {
  selectCoffeeMenu,
  useCloneCoffeeMenuMutation,
} from "../state/services/api";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as yup from "yup";
import { TFunction } from "i18next";
import TextInput from "./form/TextInput";
import { useCallback } from "react";
import { useAlert } from "./alerts/AlertContext";

export const DUPLICATE_COFFEE_MENU_DIALOG = "DUPLICATE_COFFEE_MENU_DIALOG";

const CloneMenuSchema = (t: TFunction) =>
  yup.object().shape({
    name: yup.string().required(t("required", { field: t("name") })),
  });

export function DuplicateCoffeeMenuDialog() {
  const [cloneMenu] = useCloneCoffeeMenuMutation();

  const { t } = useTranslation();

  const { entity, close } = useEntityDialog({
    name: DUPLICATE_COFFEE_MENU_DIALOG,
    selector: selectCoffeeMenu,
  });

  const alert = useAlert();

  const onSubmit = useCallback(
    async (values: typeof initialValues) => {
      if (!entity) return;
      try {
        await cloneMenu({ id: entity.id, name: values.name }).unwrap();
        close();
      } catch (e) {
        alert({
          severity: "error",
          autoHideDuration: 3000,
          content: t("coffeeMenus.cloneMenuDialog.cloneError"),
        });
      }
    },
    [alert, cloneMenu, close, entity, t]
  );

  const initialValues = {
    name: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CloneMenuSchema(t)}
      onSubmit={onSubmit}
    >
      {({ submitForm }) => (
        <EntityDialog
          name={DUPLICATE_COFFEE_MENU_DIALOG}
          title={t("coffeeMenus.overview.duplicate.title")}
          subTitle={t("coffeeMenus.overview.duplicate.confirm", {
            menu: entity?.name,
          })}
          okButton={
            <Button
              variant="contained"
              startIcon={<Save />}
              onClick={submitForm}
            >
              {capitalize(t("yes"))}
            </Button>
          }
        >
          <TextInput
            title={capitalize(t("coffeeMenus.property.name"))}
            name="name"
            sx={{ width: "100%" }}
          />
        </EntityDialog>
      )}
    </Formik>
  );
}
