import { Box, Stack } from "@mui/material";
import {
  GridCallbackDetails,
  GridColDef,
  GridLogicOperator,
  GridRowSelectionModel,
  GridSlotsComponentsProps,
} from "@mui/x-data-grid";
import { BaseDataGrid, DataGrid } from "./DataGrid";
import {
  JSXElementConstructor,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { GridPagination, GridSortItem } from "@mui/x-data-grid";
import { UserFilter, ConsumptionFilter } from "../state/users";

interface ConsumptionProps<T> {
  export?: boolean;
  pageSize?: number;
  filter?: UserFilter & ConsumptionFilter;
  rows?: T[];
  columns: GridColDef[];
  aggregateColumns?: GridColDef[];
  aggregateRows?: T[];
  Toolbar?: JSXElementConstructor<any>;
  selectionModel?: GridRowSelectionModel;
  onSelectionModelChange?: (
    selectionModel: GridRowSelectionModel,
    details: GridCallbackDetails<any>
  ) => void;
  componentsProps?: GridSlotsComponentsProps;
}

const tableSx = {
  flex: 1,
  paddingRight: 0,
  "& .MuiButtonBase-root": {
    width: "auto",
  },
  "& .wrap-header .MuiDataGrid-columnHeaderTitle": {
    whiteSpace: "pre-wrap",
    lineHeight: "1rem",
  },
  "& .MuiDataGrid-cell.wrap *": {
    whiteSpace: "pre-line!important",
  },
  "@media print": {
    pageBreakBefore: "always",
    fontSize: "8pt",
    ".prominent": {
      fontSize: "9pt!important",
    },
    ".MuiDataGrid-cellCheckbox, .MuiDataGrid-columnHeaderCheckbox": {
      display: "none",
    },
  },
  "& .base-table-main .MuiDataGrid-virtualScroller": {
    MsOverflowStyle: "none",
    scrollbarWidth: "none",
  },
  "& .base-table-main .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
    display: "none",
  },
};

function waitForElement<T extends Element>(
  el: T,
  selector: string
): Promise<T | null> {
  return new Promise((resolve) => {
    if (el.querySelector(selector)) {
      return resolve(el.querySelector(selector) as T);
    }

    const observer = new MutationObserver(() => {
      if (el.querySelector(selector)) {
        resolve(el.querySelector(selector) as T);
        observer.disconnect();
      }
    });

    observer.observe(el, {
      childList: true,
      subtree: true,
    });
  });
}

function useSelectedRef(
  rootRef: RefObject<Element>,
  selector: string,
  onChange?: (el: Element) => void
) {
  const selectedRef = useRef<Element | null>(null);
  useEffect(() => {
    let stop = false;
    Promise.resolve().then(() => {
      if (!rootRef.current) return;

      const awaitRef = async () => {
        if (!rootRef.current) return;
        const el = await waitForElement(rootRef.current, selector);
        if (stop || !el) return;
        selectedRef.current = el;
        onChange?.(el);
      };

      awaitRef();
    });
    return () => {
      stop = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange, rootRef.current, selector]);

  return selectedRef;
}

function useGridScroll(
  ref: RefObject<Element>,
  cb: (pos: { x: number; y: number }) => void
) {
  const handler = useRef<(() => void) | null>(null);

  const onChange = useCallback(
    (el: Element) => {
      handler.current = () => {
        if (el) {
          cb({
            x: el.scrollLeft,
            y: el.scrollTop,
          });
        }
      };

      el.addEventListener("scroll", handler.current, {
        capture: false,
        passive: true,
      });
    },
    [cb]
  );

  const selectedRef = useSelectedRef(
    ref,
    ".MuiDataGrid-virtualScroller",
    onChange
  );

  useEffect(
    () => () => {
      if (handler.current)
        selectedRef.current?.removeEventListener("scroll", handler.current);
    },
    [cb, handler, selectedRef]
  );
}

function useGridScrollSync(
  refSource: RefObject<Element>,
  refTarget: RefObject<Element>
) {
  const targetEl = useSelectedRef(refTarget, ".MuiDataGrid-virtualScroller");
  const sourceEl = useSelectedRef(refSource, ".MuiDataGrid-virtualScroller");

  useGridScroll(
    refSource,
    useCallback(
      ({ x, y }) => {
        const target = targetEl.current;
        if (!target) return;

        target.scrollLeft = x;
        target.scrollTop = y;
      },
      [targetEl]
    )
  );

  useGridScroll(
    refTarget,
    useCallback(
      ({ x, y }) => {
        const target = sourceEl.current;
        if (!target) return;

        target.scrollLeft = x;
        target.scrollTop = y;
      },
      [sourceEl]
    )
  );
}

function Footer<T extends { id: string }>({
  rows,
  columns,
  baseGridRef,
  totalTableColumnVisibility,
  isColumnAggregate,
  export: exportEnabled,
}: {
  rows: T[];
  export: boolean;
  columns: GridColDef[];
  baseGridRef: RefObject<HTMLDivElement>;
  totalTableColumnVisibility: Record<string, boolean>;
  isColumnAggregate: boolean;
}) {
  const gridRef = useRef(null);
  useGridScrollSync(gridRef, baseGridRef);

  return (
    <Stack direction="column">
      <Box flex="1">
        <BaseDataGrid
          ref={gridRef}
          autoHeight
          columnHeaderHeight={0}
          getRowHeight={exportEnabled ? undefined : (() => "auto")}
          rows={rows}
          columns={columns}
          hideFooter={true}
          pagination={undefined}
          columnVisibilityModel={totalTableColumnVisibility}
          checkboxSelection={!exportEnabled}
          isRowSelectable={() => false}
          disableVirtualization={true}
          sx={{
            padding: 0,
            margin: 0,
            border: "none",
            ...tableSx,
            "@media print": {
              ...tableSx["@media print"],
              pageBreakBefore: "auto",
            },
            ".MuiDataGrid-cellCheckbox": {
              visibility: "hidden",
            },
          }}
        />
      </Box>
      {!isColumnAggregate && !exportEnabled && <GridPagination />}
    </Stack>
  );
}

function AggregateTable<T extends { id: string }>({
  export: exportEnabled = false,
  aggregateRows,
  columns: originalColumns,
  aggregateColumns,
  filter,
  selectionModel,
  onSelectionModelChange,
  rows,
  Toolbar,
  pageSize = 10,
  componentsProps,
}: ConsumptionProps<T>) {
  const rowSize = 32;

  const gridRef = useRef(null);
  const [sort, setSort] = useState<GridSortItem[]>([]);
  const [page, setPage] = useState<number>(0);

  const columns = useMemo(
    () =>
      aggregateColumns
        ? originalColumns.concat(aggregateColumns)
        : originalColumns,
    [aggregateColumns, originalColumns]
  );

  const aggregateTableColumnVisibility = useMemo(
    () =>
      Object.fromEntries(
        columns
          .filter(
            (col) => !["totalConsumption", "totalPrice"].includes(col.field)
          )
          .map((col) => [col.field, false])
      ),
    [columns]
  );

  return <>
    <DataGrid
      ref={gridRef}
      tableOnly={true}
      className="users-grid"
      getRowHeight={exportEnabled ? undefined : (() => "auto")}
      sx={tableSx}
      rows={rows ?? []}
      classes={{
        main: "base-table-main",
      }}
      columns={columns}
      initialState={{
        columns: {
          columnVisibilityModel: {
            totalConsumption: false,
            totalPrice: false,
          },
        },
      }}
      paginationModel={{ page, pageSize }}
      onPaginationModelChange={(model) => setPage(model.page) }
      columnHeaderHeight={rowSize * 3}
      checkboxSelection={!exportEnabled}
      sortModel={sort}
      onSortModelChange={(sortModel) => setSort(sortModel)}
      rowSelectionModel={selectionModel}
      onRowSelectionModelChange={onSelectionModelChange}
      disableVirtualization={exportEnabled}
      getRowClassName={(params) =>
        params.row.id === "## all ##" ? "column-total" : ""
      }
      slots={{
        footer: Footer,
        toolbar: Toolbar,
      }}
      slotProps={{
        footer: {
          rows: aggregateRows,
          columns,
          baseGridRef: gridRef,
          export: exportEnabled,
          isColumnAggregate: false,
          totalTableColumnVisibility: {
            totalConsumption: false,
            totalPrice: false,
          },
        },
        filterPanel: {
          // Force usage of "And" operator
          logicOperators: [GridLogicOperator.And],
        },
        toolbar: {
          hidden: exportEnabled,
          filter,
        },
        ...componentsProps,
      }}
    />
    {aggregateColumns?.length && (
      <DataGrid
        sx={{
          paddingLeft: 0,
          paddingBottom: 0,
          flex: "0 1 280px",
          boxShadow: "rgb(0 0 0 / 21%) -2px 0px 4px -2px",
          "& .wrap-header .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "pre-wrap",
            lineHeight: "1rem",
            hyphens: "auto",
            wordBreak: "break-word",
            flex: 1,
          },
          "& .MuiButtonBase-root": {
            width: "1px",
            visibility: "hidden",
          },
        }}
        rows={rows ?? []}
        getRowHeight={exportEnabled ? undefined : (() => "auto")}
        columns={columns}
        tableOnly={true}
        paginationModel={{ pageSize, page }}
        columnHeaderHeight={rowSize * 3}
        disableColumnMenu={true}
        disableVirtualization={exportEnabled}
        initialState={{
          columns: {
            columnVisibilityModel: aggregateTableColumnVisibility,
          },
        }}
        columnVisibilityModel={aggregateTableColumnVisibility}
        checkboxSelection={!exportEnabled}
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={onSelectionModelChange}
        sortModel={sort}
        onSortModelChange={(sortModel) => setSort(sortModel)}
        slots={{
          footer: Footer,
          toolbar: Toolbar,
        }}
        slotProps={{
          footer: {
            rows: aggregateRows,
            columns,
            baseGridRef: gridRef,
            totalTableColumnVisibility: aggregateTableColumnVisibility,
            isColumnAggregate: true,
          },
          toolbar: {
            filter,
          },
          ...componentsProps,
        }}
      />
    )}
  </>;
}

export default AggregateTable;
