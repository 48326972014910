import { TFunction } from "i18next";
import { JSXElementConstructor, ReactElement } from "react";
import * as Yup from "yup";
import { BaseSchema } from "yup";
import { ObjectShape, OptionalObjectSchema, TypeOfShape } from "yup/lib/object";
import { AnyObject } from "yup/lib/types";
import { MolliePaymentOptions } from "..";
import MollieFields from "../../../page/setup/PaymentFields/MollieFields";
import { requiredName } from "../../../utils/i18n";
import { PaymentProvider } from "./interface";

export default class MollieProvider implements PaymentProvider {
  type = "mollie";

  options = ["apiKey"] as Array<keyof MolliePaymentOptions>;

  getOptionSchema(
    schema: OptionalObjectSchema<
      ObjectShape,
      AnyObject,
      TypeOfShape<ObjectShape>
    >,
    t: TFunction,
  ): BaseSchema<unknown, AnyObject, unknown> {
    return schema.shape({
      apiKey: Yup.string().required(
        requiredName(t, "setup.payment.mollie.apiKey"),
      ),
    });
  }

  fields(
    props: any,
  ): ReactElement<unknown, string | JSXElementConstructor<unknown>> {
    return MollieFields(props);
  }
}
