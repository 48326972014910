import {
  Box,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CreateCompanyForm from '../../components/CreateCompany';

const CreateCompany = () => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack
          direction="column"
          flexDirection={{ md: "row" }}
          justifyContent={{ md: "space-between" }}
          alignItems="flex-start"
        >
          <Box>
            <Typography variant="h1">
              {t("setup.company.create.title")}
            </Typography>
            <Typography>{t("setup.company.create.subTitle")}</Typography>
          </Box>
        </Stack>
      </Grid>
      <Grid item>
        <CreateCompanyForm />
      </Grid>
    </Grid>
  );
};

export default CreateCompany;
