import {
  DeleteForever,
  AttachMoney,
  Mail,
  GroupAdd,
  ShowChart,
} from "@mui/icons-material";
import {
  useMediaQuery,
  CircularProgress,
  Tooltip,
  capitalize,
  Typography,
} from "@mui/material";
import { Stack, Box, styled } from "@mui/system";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  GridValueGetterParams,
  GridColDef,
  GridValueFormatterParams,
  GridCellParams,
  GridPrintExportMenuItem,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import { endOfDay } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TemporaryAlert } from "../../components/Alert";
import Amount from "../../components/Amount";
import { DataGrid } from "../../components/DataGrid";
import { useEntitiesDialog, useEntityDialog } from "../../hooks/dialog";
import { useUsersExport } from "../../hooks/usersExport";
import { selectCurrentUser, selectCompany } from "../../state/auth";
import {
  selectUser,
  useGetCoffeeMachinesQuery,
  useGetCompanyConsumptionStatisticsQuery,
  useGetUserGroupsQuery,
  useGetUsersQuery,
  useInviteUsersMutation,
  useRemoveCompanyUsersMutation,
} from "../../state/services/api";
import {
  ConsumptionFilter,
  Role,
  Status,
  User,
  UserFilter,
} from "../../state/users";
import { getAll } from "../../utils/api";
import { formatMoney } from "../../utils/money";
import muiTheme from "../../theme";
import {
  EDIT_USER_ROLE_DIALOG_NAME,
  DELETE_USER_DIALOG_NAME,
  ADD_CREDIT_DIALOG_NAME,
  EDIT_USER_GROUP_ASSIGNMENT_DIALOG_NAME,
  CREDIT_LOG_DIALOG_NAME,
} from "./modals";
import GridActionsCellItem from "../../components/GridActionsCellItem";
import { partition } from "../../utils/array";
import ExportMenuItem from "../../components/ExportMenuItem";
import MultiselectFilter from "./filters/multiSelect";
import { mapToOptions } from "../../utils/object";
import SelectFilter from "./filters/select";
import NumberRangeFilter from "./filters/numberRange";
import { renderCellExpand } from "../../components/GridCellExpand";
import { ExportType } from "../../hooks/export";
import { useAlert } from "../../components/alerts/AlertContext";
import DeleteDialog from "../../components/DeleteDialog";
import AuthorizedButton from "../../components/AuthorizedButton";

const NO_GROUP_FILTER_KEY = "__no_group";

export enum RowAction {
  AssignToUserGroup = "assign_to_user_group",
  RemoveUser = "remove_user",
  EditUserRole = "edit_user_Role",
  AddCredit = "add_credit",
  ShowCreditLogs = "show_credit_logs",
  ResendInvitationLink = "resend_invitation_link",
}

const UserManagementExportWrapper = styled(Box)`
  padding: 4rem;
  width: 595pt;
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;
const UserManagementExport = ({
  filter,
}: {
  filter: UserFilter & ConsumptionFilter;
}) => {
  // 22 users per page
  const idParts = filter.ids ? partition(filter.ids, 22) : [[]];

  return (
    <UserManagementExportWrapper>
      {idParts.map((idPart) => (
        <UserGrid
          key={idPart.join(",")}
          export={true}
          filter={{
            ...filter,
            ids: idPart,
          }}
        />
      ))}
    </UserManagementExportWrapper>
  );
};

function UserManagementGridToolbar({
  filter,
  actions,
  hidden,
}: {
  filter: UserFilter & ConsumptionFilter;
  actions: RowAction[];
  hidden: boolean;
}) {
  const { t } = useTranslation();
  const csvExport = useUsersExport(ExportType.CSV, { filter });
  const xlsxExport = useUsersExport(ExportType.XLSX, { filter });
  const pdfExport = useUsersExport(ExportType.PDF, {
    filter,
    Component: UserManagementExport,
    componentProps: { filter },
  });

  const { edit: editUserGroupAssignment } = useEntitiesDialog({
    name: EDIT_USER_GROUP_ASSIGNMENT_DIALOG_NAME,
  });

  const { edit: editUserRole } = useEntitiesDialog({
    name: EDIT_USER_ROLE_DIALOG_NAME,
  });

  const { edit: deleteUsers } = useEntitiesDialog({
    name: DELETE_USER_DIALOG_NAME,
  });

  const { edit: addCredit } = useEntitiesDialog({
    name: ADD_CREDIT_DIALOG_NAME,
  });

  const actionActivated = useCallback(
    (action: RowAction) => !actions || actions.includes(action),
    [actions]
  );

  return (
    <GridToolbarContainer sx={{ display: hidden ? "none" : "block" }}>
      <Tooltip arrow title={t("usermanagement.export.tooltip")} placement="top">
        <Box display="inline-block">
          <GridToolbarExportContainer
            sx={{ width: "auto" }}
            disabled={!filter.ids?.length}
          >
            <ExportMenuItem
              tooltip={t("usermanagement.export.tooltipCSV")}
              onClick={async () => {
                await csvExport();
              }}
            >
              {t("usermanagement.export.exportCSV")}
            </ExportMenuItem>
            <ExportMenuItem
              tooltip={t("usermanagement.export.tooltipXLSX")}
              onClick={async () => {
                await xlsxExport();
              }}
            >
              {t("usermanagement.export.exportXLSX")}
            </ExportMenuItem>
            <ExportMenuItem
              tooltip={t("usermanagement.export.tooltipPDF")}
              onClick={async () => {
                await pdfExport();
              }}
            >
              {t("usermanagement.export.exportPDF")}
            </ExportMenuItem>
            <GridPrintExportMenuItem
              options={{
                hideFooter: true,
                hideToolbar: true,
                fields: [
                  "firstName",
                  "lastName",
                  "isKeycloakUser",
                  "numAssignedMachines",
                  "creditBalance",
                  "consumption",
                ],
              }}
            />
          </GridToolbarExportContainer>
        </Box>
      </Tooltip>
      {actionActivated(RowAction.AssignToUserGroup) && (
        <AuthorizedButton
          size="small"
          startIcon={<GroupAdd />}
          sx={{ width: "auto", ml: 2 }}
          onClick={editUserGroupAssignment(filter.ids ?? [])}
          disabled={!filter.ids?.length}
        >
          {t("usermanagement.actions.assignToUserGroup")}
        </AuthorizedButton>
      )}
      {actionActivated(RowAction.RemoveUser) && (
        <AuthorizedButton
          size="small"
          startIcon={<DeleteForever />}
          sx={{ width: "auto", ml: 2 }}
          onClick={deleteUsers(filter.ids ?? [])}
          disabled={!filter.ids?.length}
        >
          {t("usermanagement.actions.removeUser")}
        </AuthorizedButton>
      )}
      {actionActivated(RowAction.EditUserRole) && (
        <AuthorizedButton
          size="small"
          startIcon={<SettingsIcon />}
          sx={{ width: "auto", ml: 2 }}
          onClick={editUserRole(filter.ids ?? [])}
          disabled={!filter.ids?.length}
          onlyRoles={[Role.Admin]}
        >
          {t("usermanagement.actions.editUserRole")}
        </AuthorizedButton>
      )}
      {actionActivated(RowAction.AddCredit) && (
        <AuthorizedButton
          size="small"
          startIcon={<AttachMoney />}
          sx={{ width: "auto", ml: 2 }}
          onClick={addCredit(filter.ids ?? [])}
          disabled={!filter.ids?.length}
          onlyRoles={[Role.Admin]}
        >
          {t("usermanagement.actions.addCredit")}
        </AuthorizedButton>
      )}
    </GridToolbarContainer>
  );
}

const useUserDelete = () => {
  const { t } = useTranslation();

  const [deleteUsers, { isLoading: isDeleting }] =
    useRemoveCompanyUsersMutation();

  const alert = useAlert();

  const deleteUsersCallback = useCallback(
    async (ids: string[]) => {
      try {
        await deleteUsers(ids).unwrap();
        alert({
          severity: "success",
          autoHideDuration: 3000,
          content: t("usermanagement.deleteSuccess"),
        });
      } catch (e) {
        alert({
          severity: "error",
          autoHideDuration: 3000,
          content: t("usermanagement.deleteFailure"),
        });
      }
    },
    [alert, deleteUsers, t]
  );

  return {
    isDeleting,
    deleteUsersCallback: deleteUsersCallback,
  };
};

const UserGrid = ({
  export: exportEnabled = false,
  filter: filterProp,
  actions,
  roles,
  pageSize = 10,
  showFilter = true,
}: {
  export?: boolean;
  pageSize?: number;
  actions?: RowAction[];
  filter?: UserFilter & ConsumptionFilter & { fields?: string[] };
  roles?: Role[];
  showFilter?: boolean;
}) => {
  const rowSize = 32;

  const [page, setPage] = useState<number>(0);

  const { t } = useTranslation();

  const user = useSelector(selectCurrentUser);
  const company = useSelector(selectCompany);

  const { edit: editUserRoleById } = useEntityDialog({
    name: EDIT_USER_ROLE_DIALOG_NAME,
  });

  const { edit: editUserGroupAssignment } = useEntityDialog({
    name: EDIT_USER_GROUP_ASSIGNMENT_DIALOG_NAME,
  });

  const { edit: addCreditToUserId } = useEntityDialog({
    name: ADD_CREDIT_DIALOG_NAME,
  });

  const { edit: showCreditLogs } = useEntityDialog({
    name: CREDIT_LOG_DIALOG_NAME,
  });

  const { edit: deleteUser } = useEntityDialog({
    name: DELETE_USER_DIALOG_NAME,
  });

  const { deleteUsersCallback, isDeleting } = useUserDelete();

  const [
    inviteUserByEmail,
    {
      isLoading: isReinviting,
      isSuccess: isReinviteSuccess,
      isError: isReinviteError,
    },
  ] = useInviteUsersMutation();

  const smScreen = useMediaQuery<typeof muiTheme>((theme) =>
    theme.breakpoints.up("sm")
  );

  const emailOrFirstname = (params: GridValueGetterParams) =>
    params.row.firstName ?? params.row.email;

  const { data: userGroups } = useGetUserGroupsQuery();

  const getFilterFromProp = useCallback(
    () =>
      ({
        userGroupIds: getAll(userGroups).map(({ id }) => id),
        noUserGroup: true,
        fields: [] as string[],
      } as UserFilter & ConsumptionFilter),
    [userGroups]
  );

  const [filter, setFilter] = useState(getFilterFromProp());

  useEffect(() => {
    setFilter(getFilterFromProp());
  }, [company, getFilterFromProp]);

  const { data: users } = useGetUsersQuery({
    ...Object.fromEntries(
      Object.entries(filter).filter(([key]) => key !== "ids")
    ),
  });

  const ids = useMemo(() => users?.ids ?? [], [users?.ids]);

  const columns: GridColDef[] = useMemo(() => {
    const base: GridColDef[] = [
      {
        field: "firstName",
        cellClassName: "prominent",
        headerName: t("usermanagement.tableHeader.name"),
        flex: 1,
        minWidth: exportEnabled ? (smScreen ? 130 : 0) : 100,
        headerAlign: "left",
        align: "left",
        valueGetter: emailOrFirstname,
        renderCell: renderCellExpand,
      },
      {
        field: "lastName",
        headerName: t("usermanagement.tableHeader.surname"),
        minWidth: !exportEnabled ? 130 : 0,
        headerAlign: "left",
        align: "center",
        flex: 1,
        renderCell: renderCellExpand,
      },
      {
        field: "email",
        headerName: t("usermanagement.tableHeader.email"),
        minWidth: !exportEnabled ? 200 : 0,
        headerAlign: "left",
        align: "center",
        flex: 1,
        renderCell: renderCellExpand,
      },
      {
        field: "isKeycloakUser",
        headerName: t("usermanagement.tableHeader.status"),
        minWidth: !exportEnabled ? 130 : 0,
        headerAlign: "left",
        align: "center",
        flex: 1,
        valueFormatter: (params: GridValueFormatterParams) =>
          params.value ? t("active") : t("pending"),
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.isKeycloakUser && params.row.verified;
        },
      },
      {
        field: "userGroups",
        headerName: t("usermanagement.tableHeader.userGroup"),
        valueGetter: (params) =>
          params.row.userGroups[0]?.name || capitalize(t("none")),
        minWidth: !exportEnabled ? 130 : 0,
        headerAlign: "left",
        align: "center",
        flex: 1,
      },
      {
        field: "creditBalance",
        headerName: t("usermanagement.tableHeader.creditBalance"),
        minWidth: !exportEnabled ? 130 : 0,
        headerAlign: "left",
        align: "right",
        valueGetter: (params: GridValueGetterParams) => {
          if (params.row.amount !== null && params.row.currency !== null) {
            return formatMoney(params.row.amount, params.row.currency);
          }
          return formatMoney(0, company?.currency ?? "CHF");
        },
      },
    ];
    if (!!company && !!user && company.ownerId === user.id) {
      base.push({
        field: "consumption",
        headerName: t("usermanagement.tableHeader.consumption"),
        type: "string",
        minWidth: !exportEnabled ? 160 : 0,
        headerAlign: "left",
        align: "center",
        flex: 1,
        renderCell: (params: GridCellParams) => {
          const consumption = params.row.consumption as {
            [index: string]: number;
          };
          if (consumption === null) {
            return <span>0</span>;
          }
          const total: number = Object.values(consumption).reduce(
            (a, b) => Number(a) + Number(b),
            0
          );
          const text = Object.entries(consumption)
            .map(([key, value]) => `${t(`product.category.${key}`)}: ${value}`)
            .join(", ");
          return (
            <Tooltip title={text}>
              <span>{total}</span>
            </Tooltip>
          );
        },
      });
    }
    base.push({
      field: "actions",
      type: "actions",
      headerName: t("usermanagement.tableHeader.actions"),
      width: 80,
      getActions: (params: any) => {
        const rowActions = [
          <GridActionsCellItem
            key={RowAction.AssignToUserGroup}
            icon={<GroupAdd />}
            label={t("usermanagement.actions.assignToUserGroup")}
            onClick={editUserGroupAssignment(params.id)}
            showInMenu
          />,
          <GridActionsCellItem
            key={RowAction.RemoveUser}
            icon={
              isDeleting ? <CircularProgress size={20} /> : <DeleteForever />
            }
            label={t("usermanagement.actions.removeUser")}
            onClick={deleteUser(params.id)}
            showInMenu
          />,
          <GridActionsCellItem
            key={RowAction.EditUserRole}
            icon={<SettingsIcon />}
            label={t("usermanagement.actions.editUserRole")}
            onClick={editUserRoleById(params.id)}
            onlyRoles={[Role.Admin]}
            showInMenu
          />,
          <GridActionsCellItem
            key={RowAction.AddCredit}
            icon={<AttachMoney />}
            label={t("usermanagement.actions.addCredit")}
            onClick={addCreditToUserId(params.id)}
            onlyRoles={[Role.Admin]}
            showInMenu
          />,
          <GridActionsCellItem
            key={RowAction.ShowCreditLogs}
            icon={<ShowChart />}
            label={t("usermanagement.actions.showCreditLogs")}
            onClick={showCreditLogs(params.id)}
            onlyRoles={[Role.Admin]}
            showInMenu
          />,
          <GridActionsCellItem
            key={RowAction.ResendInvitationLink}
            icon={
              isReinviting ? (
                <CircularProgress size={80} thickness={5} />
              ) : (
                <Mail />
              )
            }
            label={t("usermanagement.actions.resendActivationLink")}
            onClick={() =>
              inviteUserByEmail({
                users: [
                  {
                    email: params.row.email,
                    role: params.row.role,
                  },
                ],
              })
            }
            disabled={params.row.isKeycloakUser}
            showInMenu
          />,
        ];
        return !actions?.length
          ? rowActions
          : rowActions.filter((rowAction) =>
              actions?.includes(rowAction.key as RowAction)
            );
      },
    });

    return !filter?.fields?.length
      ? base
      : base.filter((col) => filter.fields?.includes(col.field));
  }, [
    t,
    exportEnabled,
    smScreen,
    company,
    user,
    filter.fields,
    editUserGroupAssignment,
    isDeleting,
    deleteUser,
    editUserRoleById,
    addCreditToUserId,
    showCreditLogs,
    isReinviting,
    actions,
    inviteUserByEmail,
  ]);

  const visibleColumns = useMemo(
    () => ({
      firstName: true,
      lastName: smScreen,
      role: smScreen,
      lastConnection: smScreen,
      numAssignedMachines: smScreen,
      actions: !exportEnabled,
    }),
    [smScreen, exportEnabled]
  );

  const { data: allMachinesState } = useGetCoffeeMachinesQuery({});
  const allMachines = allMachinesState && getAll(allMachinesState);

  const { data: consumption, isSuccess: hasConsumptionLoaded } =
    useGetCompanyConsumptionStatisticsQuery({
      ...(filter.from && { from: filter.from.toString() }),
      ...(filter.to && { to: endOfDay(filter.to).toString() }),
    });

  const rows = ids
    .filter(
      (id) =>
        users?.entities[id] &&
        (!filterProp?.ids?.length || filterProp?.ids.includes(id as string)) &&
        (!roles?.length ||
          (users?.entities[id]?.role &&
            roles?.includes(users.entities[id]?.role as Role)))
    )
    .map((id) => ({
      ...users?.entities[id],
      numAssignedMachines:
        (users?.entities[id]?.coffeeMachines?.length ?? 0) +
        (users?.entities[id]?.locations?.flatMap(
          (location) =>
            allMachines?.filter(
              (machine) => machine.location.id === location.id
            ) ?? []
        )?.length ?? 0),
      // @ts-ignore
      consumption: consumption?.[id] || null,
    }));

  if (!hasConsumptionLoaded) {
    return <CircularProgress size={20} color="white" />;
  }

  return (
    <>
      <TemporaryAlert open={isReinviteSuccess}>
        {t("usermanagement.reinviteSuccess")}
      </TemporaryAlert>

      <TemporaryAlert open={isReinviteError} severity="error">
        {t("usermanagement.reinviteFailure")}
      </TemporaryAlert>

      <DeleteDialog
        name={DELETE_USER_DIALOG_NAME}
        entityName="usermanagement.selectedUsersForDelete"
        displayName={(entity: User) => entity?.email}
        onDelete={(id) => deleteUsersCallback([id])}
        onDeleteMany={(ids) => deleteUsersCallback(ids)}
        busy={isDeleting}
        selector={selectUser}
      />

      <DataGrid
        className="users-grid"
        tableOnly={exportEnabled}
        head={
          <>
            <Stack
              py={10}
              pl={2}
              mb={showFilter ? 0 : -4}
              mx={-2}
              borderBottom={showFilter ? "1px solid #E0E2E7" : "none"}
            >
              <Amount
                amount={users?.ids.length ?? 0}
                text={t("usermanagement.numberOfUsers")}
              />
            </Stack>
            {showFilter && (
              <>
                <Typography variant="h2" mb={5} mt={2}>
                  {capitalize(t("filter"))}
                </Typography>
                <Stack
                  direction="row"
                  alignItems="flex-start"
                  flexWrap="wrap"
                  rowGap={4}
                  columnGap={4}
                >
                  <Box
                    display="grid"
                    flex={1}
                    gap={3}
                    gridTemplateColumns="repeat(auto-fill, minmax(12rem, 1fr))"
                    sx={{ placeContent: "start" }}
                  >
                    <SelectFilter
                      name="status"
                      label={t("usermanagement.filter.status")}
                      options={Object.values(Status).map((value) => ({
                        label: capitalize(t(value)),
                        value: value,
                      }))}
                      value={filter.status}
                      setValue={(status?: string) =>
                        setFilter((f) => ({ ...f, status: status as Status }))
                      }
                    />
                    <MultiselectFilter
                      label={t("usermanagement.filter.userGroup")}
                      options={[
                        {
                          label: t("usermanagement.filter.noUserGroup"),
                          value: NO_GROUP_FILTER_KEY,
                        },
                      ].concat(
                        mapToOptions(userGroups, (userGroup) => userGroup.name)
                      )}
                      setValues={(selected) => {
                        const noUserGroupSelected =
                          selected?.includes(NO_GROUP_FILTER_KEY);
                        const ids = selected?.filter(
                          (value) => value !== NO_GROUP_FILTER_KEY
                        );
                        setFilter((f) => ({
                          ...f,
                          userGroupIds: ids,
                          noUserGroup: noUserGroupSelected,
                        }));
                      }}
                      values={(filter.noUserGroup
                        ? [NO_GROUP_FILTER_KEY]
                        : []
                      ).concat(filter.userGroupIds ?? [])}
                    />
                    <NumberRangeFilter
                      name="balance"
                      label={t("usermanagement.filter.balance")}
                      from={filter.minBalance}
                      to={filter.maxBalance}
                      setFrom={(from) =>
                        setFilter((f) => ({ ...f, minBalance: from }))
                      }
                      setTo={(to) =>
                        setFilter((f) => ({ ...f, maxBalance: to }))
                      }
                    />
                  </Box>
                </Stack>
              </>
            )}
          </>
        }
        sx={{
          "@media print": {
            pageBreakBefore: "always",
            fontSize: "8pt",
            ".prominent": {
              fontSize: "9pt!important",
            },
            ".MuiDataGrid-cellCheckbox, .MuiDataGrid-columnHeaderCheckbox": {
              display: "none",
            },
          },
        }}
        rows={rows}
        columns={columns}
        paginationModel={{ page: page ?? 0, pageSize }}
        onPaginationModelChange={(pagination) => setPage(pagination.page)}
        columnHeaderHeight={rowSize * 1.5}
        initialState={{
          columns: {
            columnVisibilityModel: visibleColumns,
          },
        }}
        checkboxSelection={!exportEnabled}
        rowSelectionModel={filter.ids}
        onRowSelectionModelChange={(selectionModel) =>
          setFilter((f) => ({
            ...f,
            ids: selectionModel.length ? (selectionModel as string[]) : [],
          }))
        }
        hideFooter={exportEnabled}
        slots={{
          toolbar: UserManagementGridToolbar,
        }}
        slotProps={{
          toolbar: {
            hidden: exportEnabled,
            filter,
            actions,
          },
        }}
      />
    </>
  );
};

export default UserGrid;
