import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  AccordionProps,
  styled,
  AccordionSummaryProps,
  AccordionDetailsProps,
  Typography,
  Stack,
} from "@mui/material";
import { ArrowForwardIosSharp, Info } from "@mui/icons-material";
import { useState } from "react";
import { FAQs } from "../../state/faq";
import { useTranslation } from "react-i18next";
import NoSpacingMarkdown from "../Markdown";

const FAQAccordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))``;

const FAQAccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background-color: #ffffff;
  font-weight: bold;

  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }
  & .MuiAccordionSummary-content {
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
`;

const FAQAccordionDetails = styled((props: AccordionDetailsProps) => (
  <MuiAccordionDetails {...props} />
))`
  padding-right: 0;
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(3)};
  border: 1px solid ${({ theme }) => theme.palette.divider};

  a {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

interface FAQCategoryProps extends Omit<AccordionProps, "children">, FAQs {}

const FAQCategory = ({ questions }: FAQCategoryProps) => {
  const [expandedFAQ, setExpandedFAQ] = useState<string | false>(false);

  const { t } = useTranslation();

  const handleFAQChange =
    (faq: string | false) =>
    (_: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedFAQ(newExpanded ? faq : false);
    };

  return (
    <Stack direction={'column'}>
      {questions.map((faq, index) => (
        <FAQAccordion
          key={index}
          disableGutters
          expanded={expandedFAQ === faq.question}
          onChange={handleFAQChange(faq.question)}
        >
          <FAQAccordionSummary>{faq.question}</FAQAccordionSummary>
          <FAQAccordionDetails>
            <Stack direction="row" alignItems="center" columnGap={1} mb={1}>
              <Info />
              <Typography fontWeight="bold">{t("help.faq.answer")}</Typography>
            </Stack>
            <NoSpacingMarkdown>{faq.answer}</NoSpacingMarkdown>
          </FAQAccordionDetails>
        </FAQAccordion>
      ))}
    </Stack>
  );
};

export default FAQCategory;
