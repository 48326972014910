import { TemporaryAlert } from "../../components/Alert";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useTranslation } from "react-i18next";
import { Box, Button, Grid } from "@mui/material";
import { Role, User } from "../../state/users";
import {
  selectUser,
  useRemoveCompanyUserMutation,
} from "../../state/services/api";
import { useEntityDialog } from "../../hooks/dialog";
import DeleteDialog from "../../components/DeleteDialog";
import AddCreditModal from "../usermanagement/AddCreditModal";
import EditRoleModal from "../usermanagement/EditRoleModal";
import {
  ADD_USER_DIALOG_NAME,
  DELETE_USER_DIALOG_NAME,
} from "../usermanagement/modals";
import NewUserModal, { UserCategory } from "../usermanagement/NewUserModal";
import UserGrid, { RowAction } from "../usermanagement/UserGrid";
import EditUserGroupAssignment from "../usermanagement/EditUserGroupAssignmentModal";
import { usePageInfo } from "../../hooks/page";
import CreditLogModal from "../usermanagement/UserCreditLogModal";

const gridFilter = {
  fields: [
    "firstName",
    "lastName",
    "isKeycloakUser",
    "numAssignedMachines",
    "actions",
  ],
};

const gridActions: RowAction[] = [
  RowAction.RemoveUser,
  RowAction.AssignToUserGroup,
  RowAction.EditUserRole,
  RowAction.ResendInvitationLink,
];

const roles: Role[] = [
  Role.Admin,
  Role.UserManager,
  Role.ProductManager,
  Role.CoffeeMachineOwner,
  Role.Reporter,
  Role.CompanyOwner,
];

const UserManagement = () => {
  const { t } = useTranslation();

  usePageInfo({
    title: t("setup.title"),
    subTitle: t("setup.operators.subTitle"),
  });

  const { open: openModal } = useEntityDialog({
    name: ADD_USER_DIALOG_NAME,
  });

  const [deleteUserById, { isLoading: isDeleting, isSuccess, isError }] =
    useRemoveCompanyUserMutation();

  return (
    <>
      <NewUserModal
        roles={Object.values(Role).filter(
          (role) => ![Role.Consumer].includes(role)
        )}
        category={UserCategory.Operator}
      />
      <EditRoleModal />
      <EditUserGroupAssignment />
      <AddCreditModal />
      <CreditLogModal />
      <DeleteDialog
        name={DELETE_USER_DIALOG_NAME}
        entityName={t("user")}
        displayName={(entity: User) => entity.email}
        onDelete={(id: string) => deleteUserById(id).unwrap()}
        busy={isDeleting}
        selector={selectUser}
      />

      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent={{ md: "flex-end" }}
          mb={2}
        >
          <Button
            sx={{
              px: 6,
              py: 1,
              my: { xs: 4, md: 0 },
              width: { sm: "auto" },
            }}
            fullWidth
            startIcon={<PersonAddIcon />}
            onClick={openModal}
            className="new-operators"
          >
            {t("usermanagement.addOperatorButton")}
          </Button>
        </Box>

        <UserGrid
          showFilter={false}
          filter={gridFilter}
          actions={gridActions}
          roles={roles}
        />
      </Grid>

      <TemporaryAlert open={isSuccess}>
        {t("usermanagement.deleteSuccess")}
      </TemporaryAlert>

      <TemporaryAlert open={isError} severity="error">
        {t("usermanagement.deleteFailure")}
      </TemporaryAlert>
    </>
  );
};

export default UserManagement;
