import { TFunction } from "i18next";
import { JSXElementConstructor, ReactElement } from "react";
import * as Yup from "yup";
import { BaseSchema } from "yup";
import { ObjectShape, OptionalObjectSchema, TypeOfShape } from "yup/lib/object";
import { AnyObject } from "yup/lib/types";
import { NexiPaymentOptions } from "..";
import NexiFields from "../../../page/setup/PaymentFields/NexiFields";
import { requiredName } from "../../../utils/i18n";
import { PaymentProvider } from "./interface";

export default class NexiProvider implements PaymentProvider {
  type = "nexi";

  options = ["apiUrl", "secretKey"] as Array<keyof NexiPaymentOptions>;

  getOptionSchema(
    schema: OptionalObjectSchema<
      ObjectShape,
      AnyObject,
      TypeOfShape<ObjectShape>
    >,
    t: TFunction,
  ): BaseSchema<unknown, AnyObject, unknown> {
    return schema.shape({
      apiUrl: Yup.string().required(
        requiredName(t, "setup.payment.nexi.apiUrl"),
      ),
      secretKey: Yup.string().required(
        requiredName(t, "setup.payment.nexi.secretKey"),
      ),
    });
  }

  fields(
    props: any,
  ): ReactElement<unknown, string | JSXElementConstructor<unknown>> {
    return NexiFields(props);
  }
}
