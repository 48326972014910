import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Option } from "../../../utils/types";
import { useParams, useHistory } from "react-router";
import { capitalize } from "../../../utils/string";
import { Trans, useTranslation } from "react-i18next";
import {
  selectCoffeeMenu,
  useDeleteCoffeeMenuMutation,
  useEditCoffeeMenuMutation,
  useGetCoffeeMenuQuery,
} from "../../../state/services/api";
import { Form, Formik } from "formik";
import { DeleteForever, Save } from "@mui/icons-material";
import { useCallback } from "react";
import * as Yup from "yup";
import TextInput from "../../../components/form/TextInput";
import DeleteDialog from "../../../components/DeleteDialog";
import { CoffeeMenu, Mode } from "../../../state/coffee-menus";
import { useEntityDialog } from "../../../hooks/dialog";
import MultiSelect from "../../../components/form/MultiSelect";
import { TFunction } from "i18next";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { minPrice } from "../../../utils/formik";
import { useSelector } from "../../../state/hooks";
import { selectCompany } from "../../../state/auth";
import { useAlert } from "../../../components/alerts/AlertContext";
import Share from "./Share";

interface MatchParams {
  menuId: string;
}

const editCoffeeMenuSchema = Yup.object().shape({
  name: Yup.string().required(),
});

const DELETE_COFFEE_MENU_DIALOG_NAME = "delete_coffee_menu";

const getModeOptions = (t: TFunction) => {
  return Object.values(Mode).map((k) => {
    return {
      value: k,
      label: capitalize(t(`coffeeMachines.mode.${k}`)),
    } as Option;
  });
};

const Overview = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const alert = useAlert();

  const { menuId } = useParams<MatchParams>();
  const company = useSelector(selectCompany);

  const { data: menu, isLoading: isLoadingMenu } =
    useGetCoffeeMenuQuery(menuId);

  const [editCoffeeMenu, { isLoading: isSaving }] = useEditCoffeeMenuMutation();

  const initialValues = {
    name: menu?.name || "",
    mode: menu?.mode ?? Mode.Payment,
  };

  const submit = useCallback(
    async (values: typeof initialValues) => {
      try {
        await editCoffeeMenu({
          id: menuId,
          body: {
            name: values.name,
            mode: values.mode ?? Mode.Payment,
          },
        }).unwrap();
        alert({
          severity: "success",
          autoHideDuration: 3000,
          content: t("coffeeMenus.editSuccess"),
        });
      } catch (e) {
        alert({
          severity: "error",
          autoHideDuration: 3000,
          content: t("coffeeMenus.editError"),
        });
      }
    },
    [alert, editCoffeeMenu, menuId, t]
  );

  const [deleteCoffeeMenuById, { isLoading: isDeleting }] =
    useDeleteCoffeeMenuMutation();

  const { edit: deleteCoffeeMenu } = useEntityDialog({
    name: DELETE_COFFEE_MENU_DIALOG_NAME,
  });

  const modeOptions: Option[] = getModeOptions(t);

  const hasInvalidPrices = useCallback(
    (mode: Mode) =>
      mode === Mode.Payment &&
      menu?.products.some(
        (product) =>
          (product.price &&
            product.price < minPrice[company?.currency ?? "CHF"]) ||
          (product.priceUpdate &&
            product.priceUpdate < minPrice[company?.currency ?? "CHF"])
      ),
    [company?.currency, menu?.products]
  );
  
  return (
    <>
      <DeleteDialog
        name={DELETE_COFFEE_MENU_DIALOG_NAME}
        entityName={t("coffeeMenu.title")}
        displayName={(entity: CoffeeMenu) => {
          console.log(entity);
          return entity.name;
        }}
        onDelete={async (id: string) => {
          try {
            await deleteCoffeeMenuById(id).unwrap();
            history.push("/coffee-menus");
            alert({
              severity: "success",
              autoHideDuration: 3000,
              content: t("coffeeMenus.deleteSuccess"),
            });
          } catch (e) {
            alert({
              severity: "error",
              autoHideDuration: 3000,
              content: t("coffeeMenus.deleteFailure"),
            });
          }
        }}
        busy={isDeleting}
        selector={selectCoffeeMenu}
        errorMessage={t("coffeeMenus.deleteFailure")}
      />

      <Grid item container xs={12}>
        <Grid item xs={12}>
          <Paper>
            <Grid sx={{ pt: { xs: 2, sm: 4 }, p: 2 }} container>
              {!menu || isLoadingMenu ? (
                <CircularProgress
                  size={20}
                  color="primary"
                  sx={{ margin: "0 auto", mt: -2 }}
                />
              ) : (
                <>
                  <Grid item xs="auto" sx={{ maxWidth: "100%!important" }}>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={editCoffeeMenuSchema}
                      enableReinitialize={true}
                      onSubmit={submit}
                    >
                      {({ handleSubmit, values }) => (
                        <Box>
                          <Form>
                            <Box
                              display="grid"
                              sx={{
                                gridTemplateColumns: "1fr 2fr",
                                gridGap: "10px",
                                alignItems: "center",
                              }}
                            >
                              <Typography sx={{ fontWeight: "bold" }}>
                                {capitalize(t("coffeeMenus.property.model"))}
                              </Typography>

                              <Typography>{menu?.model}</Typography>

                              <Typography sx={{ fontWeight: "bold" }}>
                                {capitalize(t("coffeeMenus.property.name"))}
                              </Typography>

                              <TextInput
                                name="name"
                                type="text"
                                sx={{
                                  ".MuiOutlinedInput-root": {
                                    mt: (theme) => theme.spacing(1),
                                  },
                                }}
                                formControlProps={{ sx: { mt: 0 } }}
                                disabled={menu.isDefault}
                              />

                              {hasInvalidPrices(values.mode) && (
                                <Alert
                                  severity="warning"
                                  sx={{
                                    my: 2,
                                    gridColumn: "1 / -1",
                                    "& a": {
                                      color: (theme) =>
                                        theme.palette.primary.main,
                                    },
                                  }}
                                >
                                  <AlertTitle>
                                    {t(
                                      "coffeeMenus.alerts.invalidPrices.title"
                                    )}
                                  </AlertTitle>
                                  <ReactMarkdown linkTarget="_blank">
                                    {t(
                                      "coffeeMenus.alerts.invalidPrices.text",
                                      {
                                        joinArrays: "  \n",
                                      }
                                    )}
                                  </ReactMarkdown>
                                </Alert>
                              )}

                              <Tooltip
                                arrow
                                PopperProps={{
                                  sx: {
                                    lineHeight: "1.1rem",
                                    pointerEvents: "none",
                                  },
                                }}
                                title={
                                  <>
                                    <Trans
                                      i18nKey={"products.tooltip.mode.guide"}
                                    />
                                    <br />
                                    <Trans
                                      i18nKey={`products.tooltip.mode.${values.mode}`}
                                    />
                                  </>
                                }
                              >
                                <Grid>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      display: "flex",
                                      alignItems: "center",
                                      columnGap: ".5rem",
                                    }}
                                  >
                                    {capitalize(
                                      t("coffeeMachines.property.mode")
                                    )}
                                    <InfoIcon />
                                  </Typography>
                                </Grid>
                              </Tooltip>

                              <MultiSelect
                                name="mode"
                                helperText={t("required", { field: "payment" })}
                                sx={{ mt: 0 }}
                                className="coffee-menu-mode"
                                formControlProps={{
                                  sx: { margin: 0 },
                                }}
                                disabled={menu.isDefault}
                              >
                                {modeOptions.map((option) => (
                                  <MenuItem
                                    value={option.value}
                                    key={option.value}
                                  >
                                    <Tooltip
                                      arrow
                                      PopperProps={{
                                        sx: {
                                          lineHeight: "1.1rem",
                                          pointerEvents: "none",
                                        },
                                      }}
                                      title={
                                        <Trans
                                          i18nKey={`products.tooltip.mode.${option.value}`}
                                        />
                                      }
                                    >
                                      <Box>{option.label}</Box>
                                    </Tooltip>
                                  </MenuItem>
                                ))}
                              </MultiSelect>

                              <Share sx={{ gridColumn: "1 / -1" }} />

                              <Button
                                variant="contained"
                                startIcon={<Save />}
                                onClick={() => handleSubmit()}
                                disabled={hasInvalidPrices(values.mode)}
                              >
                                {isSaving ? (
                                  <CircularProgress size={20} color="white" />
                                ) : (
                                  t("save")
                                )}
                              </Button>
                              <Button
                                sx={{ height: "100%" }}
                                startIcon={<DeleteForever />}
                                onClick={deleteCoffeeMenu(menu?.id)}
                              >
                                {isSaving ? (
                                  <CircularProgress size={20} color="white" />
                                ) : (
                                  t("coffeeMenus.removeCoffeeMenuButtonText")
                                )}
                              </Button>
                            </Box>
                          </Form>
                        </Box>
                      )}
                    </Formik>
                  </Grid>
                </>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Overview;
