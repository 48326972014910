import { ExpandMore, ChevronRight } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Collapse,
  Popover,
  Stack,
  StackProps,
  styled,
  Typography,
} from "@mui/material";
import {
  createContext,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

const InputBox = styled(Box)`
  input::placeholder {
    opacity: 0.8;
  }
`;

export interface TreeSelectProps {
  id?: string;
  name?: string;
  value?: string[];
  single?: boolean;
  raw?: boolean;
  open?: boolean;
  fullWidth?: boolean;
  children?: React.ReactNode;
  defaultExpanded?: boolean;
  renderInput?: React.ReactNode | ((nodes: Node[]) => React.ReactNode);
  onOpenChange?: (open: boolean) => void;
  onChange?: (selected: string[]) => void;
}

interface Context {
  value: string[];
  expanded: string[];
  path: string[];
  addOption: (
    nodeId: string,
    label: string | React.ReactNode,
    path: string[]
  ) => void;
  removeOption: (
    nodeId: string,
    label: string | React.ReactNode,
    path: string[]
  ) => void;
  select: (nodeId: string) => void;
  expand: (nodeId: string) => void;
  isSelected: (nodeId: string) => boolean;
  isIndeterminate: (nodeId: string) => boolean;
  isExpanded: (nodeId: string) => boolean;
}

const TreeSelectContext = createContext<Context>({
  value: [],
  expanded: [],
  path: [],
  addOption: (
    nodeId: string,
    label: string | React.ReactNode,
    path: string[]
  ) => {},
  removeOption: (
    nodeId: string,
    label: string | React.ReactNode,
    path: string[]
  ) => {},
  select: (nodeId: string) => {},
  expand: (nodeId: string) => {},
  isSelected: (nodeId: string) => false,
  isIndeterminate: (nodeId: string) => false,
  isExpanded: (nodeId: string) => false,
});

export interface Node {
  id: string;
  label: string | React.ReactNode;
  children: string[];
}

function descendants(node: string, nodes: Record<string, Node>): string[] {
  if (!nodes[node]) return [];
  const childIds = nodes[node]?.children;
  if (!childIds?.length) return [];
  return childIds.concat(childIds.flatMap((id) => descendants(id, nodes)));
}

function isLeaf(node: string, nodes: Record<string, Node>): boolean {
  return !nodes[node]?.children?.length;
}

function findLeafs(node: string, nodes: Record<string, Node>): string[] {
  return descendants(node, nodes).filter((did) => isLeaf(did, nodes));
}

function toHigherHierarchy(
  selected: string[],
  nodes: Record<string, Node>
): string[] {
  const allSelected = selected.slice();

  const selectChildren = (node: Node) => {
    let endSet: string[] = [];
    let fromChildren: string[] = [];
    const children = node.children;
    // Descend
    for (const child of children) {
      fromChildren = fromChildren.concat(selectChildren(nodes[child]));
    }

    allSelected.concat(fromChildren);

    const selectedChildren = children.filter((c) => allSelected.includes(c));

    // Push leafs and / or nodes of which all children are selected.
    if (
      (!children.length && allSelected.includes(node.id)) ||
      (children.length && selectedChildren.length === children.length)
    ) {
      endSet.push(node.id);
    } else {
      endSet = endSet.concat(fromChildren);
    }

    return endSet;
  };

  const root = nodes.root;
  let all: string[] = [];
  for (const node of root.children) {
    all = all.concat(selectChildren(nodes[node]));
  }
  return all;
}

function toLeafs(selected: string[], nodes: Record<string, Node>): string[] {
  let leafs: string[] = [];
  for (const node of selected) {
    if (!nodes[node]?.children?.length) {
      leafs.push(node);
    } else {
      leafs = leafs.concat(findLeafs(node, nodes));
    }
  }
  return leafs;
}

const TreeSelect = ({
  value,
  children,
  onChange,
  renderInput,
  single,
  raw,
  open: isOpen,
  onOpenChange,
  defaultExpanded,
  fullWidth,
  ...props
}: TreeSelectProps) => {
  const [open, setOpen] = useState(isOpen);
  const [localValue, setLocalValue] = useState([] as string[]);
  const [expanded, setExpanded] = useState([] as string[]);
  const [options, setOptions] = useState({
    root: { id: "root", label: "root", children: [] },
  } as Record<string, Node>);
  const input = useRef(null);

  useEffect(() => {
    if (localValue !== value) {
      if (single) {
        setLocalValue(value ?? []);
      } else {
        setLocalValue(toLeafs(value ?? [], options));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, options]);

  const handleClose = useCallback(() => {
    setOpen(false);
    onOpenChange?.(false);
  }, [onOpenChange]);

  const handleOpen = useCallback(() => {
    setOpen(true);
    onOpenChange?.(true);
  }, [onOpenChange]);

  const isSelected = useCallback(
    (nodeId: string) => {
      if (single || isLeaf(nodeId, options)) {
        return localValue.includes(nodeId);
      } else {
        const leafs = findLeafs(nodeId, options);
        return leafs.every((l) => localValue.includes(l));
      }
    },
    [localValue, options, single]
  );

  const isIndeterminate = useCallback(
    (nodeId: string) => {
      const allNodeIds = descendants(nodeId, options).concat(nodeId);
      return !isSelected(nodeId) && allNodeIds.some(isSelected);
    },
    [isSelected, options]
  );

  const isExpanded = useCallback(
    (nodeId: string) => {
      return expanded.includes(nodeId);
    },
    [expanded]
  );

  const handleSelect = useCallback(
    (nodeId: string) => {
      let nextValue: (value: string[]) => string[];
      if (single) {
        nextValue = (value: string[]) => (isSelected(nodeId) ? [] : [nodeId]);
        setLocalValue(nextValue);
        onChange?.(nextValue(localValue));
        return;
      } else if (isSelected(nodeId) && !isIndeterminate(nodeId)) {
        if (isLeaf(nodeId, options)) {
          nextValue = (value: string[]) => value.filter((v) => v !== nodeId);
        } else {
          const leafs = findLeafs(nodeId, options);
          nextValue = (value: string[]) =>
            value.filter((v) => !leafs.includes(v));
        }
      } else {
        if (isLeaf(nodeId, options)) {
          nextValue = (value: string[]) => value.concat(nodeId);
        } else {
          const leafs = findLeafs(nodeId, options);
          nextValue = (value: string[]) =>
            Array.from(new Set(value.concat(leafs)));
        }
      }

      setLocalValue(nextValue);
      onChange?.(toHigherHierarchy(nextValue(localValue), options));
    },
    [isSelected, isIndeterminate, options, onChange, localValue, single]
  );

  const handleExpand = useCallback(
    (nodeId: string) => {
      if (isExpanded(nodeId)) {
        setExpanded((value) => value.filter((v) => v !== nodeId));
      } else {
        setExpanded((value) => value.concat(nodeId));
      }
    },
    [isExpanded]
  );

  const addOption = useCallback(
    (nodeId: string, label: string | React.ReactNode, path: string[]) => {
      const parentId = path[path.length - 1];
      if (defaultExpanded) {
        setExpanded((expanded) => expanded.concat(nodeId));
      }

      setOptions((opts) => ({
        ...opts,
        [nodeId]: {
          ...opts[nodeId],
          id: nodeId,
          label,
          children: opts[nodeId]?.children ?? [],
        },
        [parentId]: {
          ...opts[parentId],
          id: parentId,
          children: ([] as string[]).concat(
            opts[parentId]?.children ?? [],
            nodeId
          ),
        },
      }));
    },
    [defaultExpanded]
  );

  const removeOption = useCallback(
    (nodeId: string, label: string | React.ReactNode, path: string[]) => {
      const parentId = path[path.length - 1];
      setOptions((opts) => {
        const nextOptions = Object.fromEntries(
          Object.entries(opts ?? []).filter(([id]) => id !== nodeId)
        );
        return {
          ...nextOptions,
          [parentId]: {
            ...nextOptions[parentId],
            children:
              nextOptions[parentId]?.children?.filter(
                (id: string) => id !== nodeId
              ) ?? [],
          },
        };
      });
    },
    []
  );

  const nodes = value?.map((v) => options[v]).filter((v) => v);

  return (
    <TreeSelectContext.Provider
      value={{
        value: localValue,
        expanded,
        path: ["root"],
        isSelected,
        isIndeterminate,
        isExpanded,
        expand: handleExpand,
        select: handleSelect,
        addOption,
        removeOption,
      }}
    >
      <Box
        className="TreeSelect-root"
        sx={{ width: fullWidth ? "100%" : undefined }}
      >
        {!raw ? (
          <>
            <InputBox
              className="TreeSelect-inputBox"
              sx={{ marginRight: '5px', width: fullWidth ? "100%" : undefined }}
              ref={input}
              onClick={handleOpen}
            >
              {typeof renderInput === "function"
                ? renderInput(nodes ?? [])
                : renderInput}
            </InputBox>
            <Popover
              open={Boolean(input.current && open)}
              BackdropProps={{
                sx: { backgroundColor: "transparent" },
              }}
              anchorEl={input.current}
              onClose={handleClose}
              keepMounted={true}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              {...props}
            >
              <Box padding={1}>{children}</Box>
            </Popover>
          </>
        ) : (
          <Box padding={1}>{children}</Box>
        )}
      </Box>
    </TreeSelectContext.Provider>
  );
};

interface TreeSelectItemProps extends StackProps {
  nodeId: string;
  label?: string;
  hideCheckbox?: boolean;
  renderLabel?: React.ReactNode;
  children?: React.ReactNode;
  disabled?: boolean;
}

export const TreeSelectItem = ({
  nodeId,
  label,
  hideCheckbox,
  renderLabel,
  children,
  disabled,
  ...props
}: TreeSelectItemProps) => {
  const ctx = useContext(TreeSelectContext);
  const {
    select,
    expand,
    addOption,
    removeOption,
    isSelected,
    isIndeterminate,
    isExpanded,
    path,
  } = ctx;

  useEffect(() => {
    addOption(nodeId, label || renderLabel, path);
    return () => removeOption(nodeId, label || renderLabel, path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addOption, removeOption, nodeId, path.join(","), label]);

  const handleExpand = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      expand(nodeId);
    },
    [nodeId, expand]
  );

  return (
    <Stack className="TreeSelectItem">
      <Stack
        direction="row"
        {...props}
        onClick={() => !disabled && select(nodeId)}
        sx={{ alignItems: "center", cursor: "pointer" }}
      >
        <Stack
          onClick={handleExpand}
          sx={{ height: "100%", alignItems: "center" }}
        >
          {isExpanded(nodeId) ? (
            <ExpandMore sx={{ visibility: children ? "visible" : "hidden" }} />
          ) : (
            <ChevronRight
              sx={{ visibility: children ? "visible" : "hidden" }}
            />
          )}
        </Stack>
        {!hideCheckbox && (
          <Checkbox
            checked={isSelected(nodeId)}
            indeterminate={isIndeterminate(nodeId)}
          />
        )}
        {renderLabel ? renderLabel : <Typography>{label}</Typography>}
      </Stack>
      {children && (
        <Collapse in={isExpanded(nodeId)}>
          <Box sx={{ ml: 2 }}>
            <TreeSelectContext.Provider
              value={{ ...ctx, path: path.concat(nodeId) }}
            >
              {children}
            </TreeSelectContext.Provider>
          </Box>
        </Collapse>
      )}
    </Stack>
  );
};

export default TreeSelect;
