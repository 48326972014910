import { DeleteForever, Edit, GroupAdd, Save } from "@mui/icons-material";
import {
  Box,
  Grid,
  Button,
  Typography,
  capitalize,
  CircularProgress,
  Chip,
  InputAdornment,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  selectUserGroup,
  useDeleteUserGroupMutation,
  useGetUserGroupsQuery,
  useEditUserGroupMutation,
  useCreateUserGroupMutation,
  useGetUsersQuery,
  useAssignUserGroupsMutation,
} from "../../state/services/api";
import {
  RecurringTopUpInstant,
  RecurringTopUpInterval,
  RecurringTopUpIntervalRangeType,
  UserGroup,
} from "../../state/user-groups";
import { TemporaryAlert } from "../../components/Alert";
import TileGrid from "../../components/TileGrid";
import Tile from "../../components/Tile";
import TileMenuButton from "../../components/TileMenuButton";
import Amount from "../../components/Amount";
import { useEntityDialog } from "../../hooks/dialog";
import DeleteDialog from "../../components/DeleteDialog";
import EntityDialog from "../../components/EntityDialog";
import TextInput from "../../components/form/TextInput";
import { getAll } from "../../utils/api";
import MultiSelect from "../../components/form/MultiSelect";
import { TFunction } from "i18next";
import { ellipsisOverflow } from "../../utils/string";
import Checkbox from "../../components/form/Checkbox";
import DatePicker from "../../components/form/DatePicker";
import { parseISO, startOfDay, sub } from "date-fns";

const USER_GROUP_EDIT_DIALOG_NAME = "setup.user_group.edit";
const USER_GROUP_DELETE_DIALOG_NAME = "setup.user_group.delete";

const UserGroupSchema = (t: TFunction) =>
  Yup.object().shape({
    name: Yup.string().max(255, t("validation.maxLength")).required(),
    recurringTopUpEnabled: Yup.boolean(),
    recurringTopUpIntervalAmount: Yup.number().when("recurringTopUpEnabled", {
      is: true,
      then: (schema) =>
        schema.required(
          t("required", {
            field: "setup.addUserGroupModal.recurringTopUp.amount",
          })
        ),
    }),
    recurringTopUpInstant: Yup.mixed().when("recurringTopUpEnabled", {
      is: true,
      then: (schema) =>
        schema
          .oneOf(
            Object.values(RecurringTopUpInstant),
            t("required", {
              field: "setup.addUserGroupModal.recurringTopUp.instant",
            })
          )
          .required(
            t("required", {
              field: "setup.addUserGroupModal.recurringTopUp.instant",
            })
          ),
    }),
    recurringTopUpInterval: Yup.string()
      .when("recurringTopUpEnabled", {
        is: true,
        then: (schema) =>
          schema
            .required(
              t("required", {
                field: "setup.addUserGroupModal.recurringTopUp.interval",
              })
            )
            .oneOf(
              Object.values(RecurringTopUpInterval),
              t("required", {
                field: "setup.addUserGroupModal.recurringTopUp.interval",
              })
            ),
      })
      .nullable(),
    recurringTopUpStart: Yup.mixed().when("recurringTopUpInterval", {
      is: RecurringTopUpInterval.Custom,
      then: (schema) =>
        schema.required(
          t("required", {
            field: "setup.addUserGroupModal.recurringTopUp.start",
          })
        ),
    }),
    recurringTopUpIntervalRange: Yup.string()
      .nullable()
      .when("recurringTopUpInterval", {
        is: RecurringTopUpInterval.Custom,
        then: (schema) =>
          schema.required(
            t("required", {
              field: "setup.addUserGroupModal.recurringTopUp.range",
            })
          ),
      }),
  });

const UserGroupDialog = () => {
  const { t } = useTranslation();

  const { entity: userGroup, close } = useEntityDialog({
    name: USER_GROUP_EDIT_DIALOG_NAME,
    selector: selectUserGroup,
  });

  const [
    createUserGroup,
    {
      isLoading: isSavingCreate,
      isSuccess: isSuccessCreate,
      isError: isErrorCreate,
    },
  ] = useCreateUserGroupMutation();

  const [
    editUserGroup,
    { isLoading: isSavingEdit, isSuccess: isSuccessEdit, isError: isErrorEdit },
  ] = useEditUserGroupMutation();

  const [
    editUserGroupAssignment,
    {
      isLoading: isSavingAssignment,
      isSuccess: isSuccessAssignment,
      isError: isErrorAssignment,
    },
  ] = useAssignUserGroupsMutation();

  const { data: usersState } = useGetUsersQuery();
  const users = getAll(usersState);

  const [isSaving, isError, isSuccess] = [
    isSavingCreate || isSavingEdit || isSavingAssignment,
    isErrorCreate || isErrorEdit || isErrorAssignment,
    isSuccessCreate || isSuccessEdit || isSuccessAssignment,
  ];

  const userOptions = users.map((user) => ({
    label: !user.firstName ? user.email : `${user.firstName} ${user.lastName}`,
    value: user.id,
  }));

  const intervalOptions = Object.values(RecurringTopUpInterval).map(
    (intervalChoice) => ({
      label: t(`userGroup.recurringTopUpInterval.${intervalChoice}`),
      value: intervalChoice,
    })
  );

  const instantOptions = Object.values(RecurringTopUpInstant).map(
    (instantChoice) => ({
      label: t(`userGroup.recurringTopUpInstant.${instantChoice}`),
      value: instantChoice,
    })
  );

  const intervalRangeTypeOptions = Object.values(
    RecurringTopUpIntervalRangeType
  ).map((intervalRangeTypeChoice) => ({
    label: t(
      `userGroup.recurringTopUpIntervalRangeType.${intervalRangeTypeChoice}`
    ),
    value: intervalRangeTypeChoice,
  }));

  const initialValues = {
    id: userGroup?.id,
    name: userGroup?.name ?? "",
    userIds: userGroup?.users.map(({ id }) => id) ?? [],
    recurringTopUpEnabled: userGroup?.recurringTopUpEnabled ?? false,
    recurringTopUpInstant: userGroup?.recurringTopUpInstant ?? undefined,
    recurringTopUpInterval: userGroup?.recurringTopUpInterval ?? undefined,
    recurringTopUpIntervalRange:
      userGroup?.recurringTopUpIntervalRange ?? undefined,
    recurringTopUpIntervalRangeType:
      userGroup?.recurringTopUpIntervalRangeType ??
      RecurringTopUpIntervalRangeType.Day,
    recurringTopUpIntervalAmount:
      userGroup?.recurringTopUpIntervalAmount ?? 0.0,
    recurringTopUpStart: userGroup?.recurringTopUpStart
      ? new Date(userGroup.recurringTopUpStart)
      : undefined,
    recurringTopUpLimit: userGroup?.recurringTopUpLimit ?? undefined,
    hasLimit: typeof userGroup?.recurringTopUpLimit === "number",
  };

  const submit = useCallback(
    async (values: typeof initialValues) => {
      let currentUserGroup = userGroup;

      const start =
        typeof values.recurringTopUpStart === "string"
          ? startOfDay(parseISO(values.recurringTopUpStart))
          : values.recurringTopUpStart;

      const recurringTopUpStart = start
        ? sub(start, { minutes: start.getTimezoneOffset() })
        : null;

      let recurringTopUpLimit = values.hasLimit
        ? values.recurringTopUpLimit
        : null;

      if (typeof recurringTopUpLimit !== "number") {
        recurringTopUpLimit = null;
      }

      const recurringTopUp = values.recurringTopUpEnabled
        ? {
            recurringTopUpEnabled: true,
            recurringTopUpInstant: values.recurringTopUpInstant,
            recurringTopUpInterval: values.recurringTopUpInterval ?? undefined,
            recurringTopUpIntervalAmount:
              values.recurringTopUpIntervalAmount ?? undefined,
            recurringTopUpStart: recurringTopUpStart ?? undefined,
            recurringTopUpIntervalRange: values.recurringTopUpIntervalRange,
            recurringTopUpIntervalRangeType:
              values.recurringTopUpIntervalRangeType,
            recurringTopUpLimit,
          }
        : {
            recurringTopUpEnabled: false,
          };

      if (values.id) {
        currentUserGroup = await editUserGroup({
          id: values.id,
          name: values.name,
          ...recurringTopUp,
        }).unwrap();
      } else {
        currentUserGroup = await createUserGroup({
          name: values.name,
          ...recurringTopUp,
        }).unwrap();
      }
      if (values.userIds && currentUserGroup) {
        let userIds = values.userIds;
        const oldUserRemovals = Object.fromEntries(
          userGroup?.users.map(({ id }) => [id, []]) ?? []
        );
        const newUserAssignments = Object.fromEntries(
          userIds.map((userId) => [userId, [currentUserGroup!.id]])
        );
        await editUserGroupAssignment({
          ...oldUserRemovals,
          ...newUserAssignments,
        });
      }
      close();
    },
    [userGroup, close, editUserGroup, createUserGroup, editUserGroupAssignment]
  );

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={UserGroupSchema(t)}
        enableReinitialize={true}
        onSubmit={submit}
      >
        {({ handleSubmit, values }) => (
          <EntityDialog
            name={USER_GROUP_EDIT_DIALOG_NAME}
            title={userGroup?.name || t("setup.addUserGroupModal.title")}
            subTitle={
              userGroup
                ? t("setup.editUserGroup")
                : t("setup.addUserGroupModal.subTitle")
            }
            okButton={
              <Button
                variant="contained"
                startIcon={!isSaving && <Save />}
                onClick={() => handleSubmit()}
              >
                {isSaving ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  t("save")
                )}
              </Button>
            }
          >
            <Box>
              <Form>
                <TextInput
                  name="name"
                  title={t("setup.addUserGroupModal.userGroupName") + ":"}
                />

                <MultiSelect
                  name="userIds"
                  title={t("setup.addUserGroupModal.userGroupUsers") + ":"}
                  options={userOptions}
                  multiple
                  includeAllOption
                  renderValue={(options) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5,
                      }}
                    >
                      {options.map(({ value, label }) => (
                        <Chip key={value} label={label} />
                      ))}
                    </Box>
                  )}
                />

                <Typography variant="h4" mt={3}>
                  {t("setup.addUserGroupModal.recurringTopUp.self")}
                </Typography>

                <Checkbox name="recurringTopUpEnabled">
                  {t("setup.addUserGroupModal.recurringTopUp.enabled")}
                </Checkbox>

                {values.recurringTopUpEnabled && (
                  <>
                    <MultiSelect
                      name="recurringTopUpInterval"
                      title={
                        t("setup.addUserGroupModal.recurringTopUp.interval") +
                        ":"
                      }
                      options={intervalOptions}
                    />
                    {values.recurringTopUpInterval ===
                      RecurringTopUpInterval.Custom && (
                      <>
                        <TextInput
                          name="recurringTopUpIntervalRange"
                          title={
                            t("setup.addUserGroupModal.recurringTopUp.range") +
                            ":"
                          }
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <MultiSelect
                                  name="recurringTopUpIntervalRangeType"
                                  options={intervalRangeTypeOptions}
                                  formControlProps={{ sx: { m: 0 } }}
                                  sx={{ m: 0, py: 1 }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <DatePicker
                          name="recurringTopUpStart"
                          title={
                            t("setup.addUserGroupModal.recurringTopUp.start") +
                            ":"
                          }
                          format="dd.MM.yyyy"
                        />
                      </>
                    )}
                    <MultiSelect
                      name="recurringTopUpInstant"
                      title={
                        t("setup.addUserGroupModal.recurringTopUp.instant") +
                        ":"
                      }
                      options={instantOptions}
                    />
                    <TextInput
                      name="recurringTopUpIntervalAmount"
                      type="number"
                      title={
                        t("setup.addUserGroupModal.recurringTopUp.amount") + ":"
                      }
                    />

                    <Box mt={2}>
                      <Checkbox name="hasLimit">
                        {t("setup.addUserGroupModal.recurringTopUp.hasLimit")}
                      </Checkbox>
                    </Box>

                    {values.hasLimit && (
                      <TextInput
                        name="recurringTopUpLimit"
                        type="number"
                        formControlProps={{ sx: { mt: 0 } }}
                        title={
                          t("setup.addUserGroupModal.recurringTopUp.limit") +
                          ":"
                        }
                      />
                    )}
                  </>
                )}
              </Form>
            </Box>
          </EntityDialog>
        )}
      </Formik>
      <TemporaryAlert open={isSuccess}>
        {t("setup.addUserGroupModal.saveSuccess")}
      </TemporaryAlert>
      <TemporaryAlert open={isError} severity="error">
        {t("setup.addUserGroupModal.saveFailure")}
      </TemporaryAlert>
    </>
  );
};

const UserGroups = () => {
  const { t } = useTranslation();
  const { data: userGroupState, isLoading } = useGetUserGroupsQuery();

  const userGroups = getAll(userGroupState);

  const [deleteUserGroupById, { isLoading: isDeleting }] =
    useDeleteUserGroupMutation();

  const { open, edit } = useEntityDialog<UserGroup>({
    name: USER_GROUP_EDIT_DIALOG_NAME,
    selector: selectUserGroup,
  });

  const { edit: promptDelete } = useEntityDialog<UserGroup>({
    name: USER_GROUP_DELETE_DIALOG_NAME,
  });

  return (
    <>
      <DeleteDialog
        name={USER_GROUP_DELETE_DIALOG_NAME}
        entityName={t("userGroup.entityName")}
        displayName={(entity: UserGroup) => entity.name}
        onDelete={(id: string) => deleteUserGroupById(id).unwrap()}
        busy={isDeleting}
        selector={selectUserGroup}
      />
      <Grid item xs>
        <Amount
          sx={{ my: 2 }}
          amount={userGroups?.length ?? 0}
          text={t("setup.numberOfUserGroups")}
        />
        <Button
          sx={{ mt: 4, width: { sm: "auto" } }}
          fullWidth
          startIcon={<GroupAdd />}
          onClick={open}
        >
          {t("setup.addUserGroupButton")}
        </Button>
        <UserGroupDialog />
      </Grid>
      <TileGrid item busy={isLoading} ids={userGroupState?.ids}>
        {userGroups?.map((userGroup: UserGroup) => (
          <Tile
            id={userGroup.id.toString()}
            title={ellipsisOverflow(userGroup.name, 40)}
            key={userGroup.id}
            menu={[
              <TileMenuButton
                key="edit"
                icon={<Edit />}
                onClick={edit(userGroup.id.toString())}
              >
                {capitalize(t("edit"))}
              </TileMenuButton>,
              <TileMenuButton
                key="remove"
                icon={
                  isDeleting ? (
                    <CircularProgress size={20} />
                  ) : (
                    <DeleteForever />
                  )
                }
                onClick={promptDelete(userGroup.id.toString())}
              >
                {capitalize(t("remove"))}
              </TileMenuButton>,
            ]}
          >
            <Typography fontWeight="bold" fontSize={10}>
              {capitalize(t("machines"))}
            </Typography>
            <Typography>{userGroup.coffeeMachines.length}</Typography>
            <Typography fontWeight="bold" fontSize={10}>
              {capitalize(t("users"))}
            </Typography>
            <Typography>{userGroup.users.length}</Typography>
          </Tile>
        ))}
      </TileGrid>
    </>
  );
};

export default UserGroups;
