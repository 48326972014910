import {
  capitalize,
  FormControl,
  FormControlProps,
  InputLabel,
  TextFieldProps,
} from "@mui/material";
import {
  DateValidationError,
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";
import { FieldHookConfig, useField } from "formik";
import { useMemo } from "react";
import { useCallback } from "react";

type DatePickerProps<T> = Omit<
  MuiDatePickerProps<T> & FieldHookConfig<T>,
  "value" | "name" | "children" | "onChange" | "ref" | "renderInput"
> & {
  title: string;
  helperText?: string;
  formControlProps?: FormControlProps;
  textInputProps?: TextFieldProps;
  onChange?: MuiDatePickerProps<T>["onChange"];
  name: string;
};

const DatePicker = ({
  title,
  helperText,
  formControlProps,
  textInputProps,
  name,
  onChange,
  defaultValue,
  ...props
}: DatePickerProps<Date | null>) => {
  const [field, meta, helpers] = useField({
    ...(textInputProps as Partial<FieldHookConfig<Date | null>>),
    name,
    type: "text",
    value: defaultValue,
  });
  const showError = meta.touched && !!meta.error;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoField = useMemo(() => field, Object.values(field));
  const change = useCallback(
    (newValue: Date | null, context: PickerChangeHandlerContext<DateValidationError>) => {
      helpers.setValue(newValue);
      onChange?.(newValue, context);
    },
    [onChange, helpers]
  );

  return (
    <FormControl margin="normal" fullWidth {...formControlProps}>
      <InputLabel shrink>{title}</InputLabel>

      <MuiDatePicker
        {...memoField}
        {...props}
        onChange={change}
        slotProps={{
          textField: (params) => ({
            id: name,
            error: showError,
            helperText: capitalize((showError ? meta.error : helperText) || ""),
            type: "text",
            ...textInputProps,
            value: params.value || "",
          }),
        }}
      />
    </FormControl>
  );
};

export default DatePicker;
