import { isAfter } from "date-fns";
import { RootState } from "../store";
import { Role, User } from "../users";

export interface Company {
  id: string;
  name: string;
  currency: string;
  owner?: User;
  ownerId?: string;
  region?: string | null;
  timezone: string;
  users?: User[];
  role?: Role;
  parentCompanyId: string;
  defaultPaymentId: string;
}

export type AuthState = {
  user?: User;
  companies?: Company[];
  token?: string;
  logoutDialogOpen: boolean;
  selectedCompanyId: string;
};

export const TOKEN_KEY = "access_token";
export const COMPANY_ID = "company_id";

export function getSelectedCompanyId(prevId: string, companies: Company[]) {
  if (!prevId) return companies[0]?.id;
  if (companies.find((company) => company.id === prevId)) {
    return prevId;
  } else {
    return companies[0]?.id;
  }
}

export const verifyToken = (token?: string) => {
  if (!token || !token.indexOf(".")) return false;
  const payloadJSON = atob(token.split(".")[1]);
  const payload = JSON.parse(payloadJSON);
  const expirationDate = new Date(payload.exp * 1000);
  return isAfter(expirationDate, Date.now());
};

export const routeRoles = {
  dashboard: [
    Role.Admin,
    Role.ProductManager,
    Role.CoffeeMachineOwner,
    Role.Reporter,
  ],
  products: [
    Role.Admin,
    Role.ProductManager,
    Role.CoffeeMachineOwner,
    Role.Reporter,
  ],
  coffeeMachines: [Role.Admin, Role.ProductManager, Role.CoffeeMachineOwner],
  coffeeMenus: [Role.Admin, Role.ProductManager],
  setup: [Role.Admin, Role.ProductManager],
  userManagement: [Role.Admin, Role.UserManager],
};

export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectToken = (state: RootState) => state.auth.token;
export const selectIsLoggedIn = (state: RootState) => !!state.auth.token;
export const selectSelectedCompanyId = (state: RootState) => state.auth.selectedCompanyId;
export const selectCompany = (state: RootState) =>
  state.auth.selectedCompanyId
    ? state.auth.companies?.find(
        (company: Company) => company.id === state.auth.selectedCompanyId
      )
    : null;

export const selectCompanies = (state: RootState) => state.auth.companies;
export const selectPaymentId = (state: RootState) =>
  selectCompany(state)?.defaultPaymentId;
export const selectLogoutDialogOpen = (state: RootState) =>
  state.auth.logoutDialogOpen;
