import { EntityState } from "@reduxjs/toolkit";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { Location, locationsAdapter } from "../locations";
import {
  ConsumptionFilter,
  CreditLog,
  RegisterUser,
  Role,
  User,
  UserConsumptions,
  UserFilter,
  usersAdapter,
} from "../users/adapter";
import { AppThunk, RootState } from "../store";
import {
  NewWidget,
  SortedWidgetIds,
  Widget,
  widgetsAdapter,
} from "../widgets/adapter";
import {
  Company,
  selectSelectedCompanyId,
  selectToken,
  verifyToken,
} from "../auth/core";
import {
  CoffeeMachine,
  coffeeMachinesAdapter,
  EditMachineArgs,
  PreselectMode,
} from "../coffe-machines";
import {
  Consumption,
  NewProduct,
  Product,
  productsAdapter,
  SortedProductIds,
} from "../products/adapter";
import { Payment } from "../payments";
import { Statistic } from "../statistics";
import { paymentsAdapter } from "../payments/adapter";
import { Filter } from "../utils/filterSlice";
import { CoffeeMenu, coffeeMenusAdapter, EditMenuArgs } from "../coffee-menus";
import { UserGroup, userGroupsAdapter } from "../user-groups";
import { CreditTopUp, creditTopUpsAdapter } from "../credit-top-ups";
import { ExportType } from "../../hooks/export";
import { Notification, NotificationType } from "../notifications";
import { FAQs } from "../faq";

async function exportResponseHandler(response: Response) {
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () =>
      resolve((reader.result as string).split(",").slice(1).join(""));
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

function asFormData(object: Record<string, string | Blob>): FormData {
  const formData = new FormData();
  for (const [key, value] of Object.entries(object)) {
    if (Array.isArray(value)) {
      for (const [i, v] of Object.entries(value)) {
        formData.append(`${key}[${i}]`, v);
      }
    } else {
      formData.append(key, value);
    }
  }
  return formData;
}

function provideListTags<T extends object, TagT extends string>(tag: TagT) {
  return (result?: EntityState<T & { id: string }>) =>
    result
      ? [
          ...result.ids.map((id) => ({ type: tag, id })),
          { type: tag, id: "LIST" },
        ]
      : [{ type: tag, id: "LIST" }];
}

interface LoginResponse {
  user: User;
  company: Company;
  companies: Company[];
  token: string;
  role: Role;
}

const rawBaseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;

    if (token) {
      headers.set("authorization", `bearer ${token}`);
    }

    return headers;
  },
});

const createQuery = (ob: Record<string, string | string[]>) => {
  const query = Object.entries(ob)
    .filter(([, value]) => value !== null && value !== undefined)
    .map(([key, value]) => {
      if (!Array.isArray(value)) {
        return `${key}=${value.toString()}`;
      }
      return `${key}=${encodeURIComponent(JSON.stringify(value))}`;
    })
    .filter((result) => result)
    .join("&");
  return query;
};

// Define url replacements here
const urlReplacements: Record<
  string,
  (state: RootState) => string | undefined
> = {
  ":companyId": (state: RootState) => selectSelectedCompanyId(state),
};

let logoutAction: () => AppThunk;

export function setLogoutAction(fn: () => AppThunk) {
  logoutAction = fn;
}

// We use this base query to replace common url patterns (e.g. :companyId)
// with parts of the state
const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const url = typeof args === "string" ? args : args.url;
  const urlParts = url.split("/");

  const token = selectToken(api.getState() as RootState);

  if (api && token && !verifyToken(token) && logoutAction) {
    api.dispatch(logoutAction());
  }

  const newUrl = urlParts
    .reduce(
      (targetParts, part) =>
        targetParts.concat(
          typeof urlReplacements[part] === "function"
            ? urlReplacements[part]?.(api.getState() as RootState) ?? part
            : part
        ),
      [] as string[]
    )
    .join("/");

  const replacedArgs =
    typeof args === "string" ? newUrl : { ...args, url: newUrl };
  return rawBaseQuery(replacedArgs, api, extraOptions);
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: dynamicBaseQuery,
  tagTypes: [
    "Me",
    "Location",
    "User",
    "Company",
    "Payment",
    "CoffeeMachine",
    "AvailableProduct",
    "Product",
    "CoffeeMachineConsumption",
    "Currency",
    "Language",
    "Widget",
    "Statistic",
    "CoffeeMenu",
    "Model",
    "UserGroup",
    "UserConsumption",
    "CreditTopUp",
    "CreditLog",
    "Notifications",
    "FAQ",
  ],
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, Pick<User, "email" | "password">>({
      query: (user) => ({
        url: `api/login`,
        method: "POST",
        body: user,
      }),
      invalidatesTags: ["User"],
    }),
    logout: builder.query({
      query: () => ({
        url: `api/logout`,
        method: "POST",
      }),
    }),
    exchangeToken: builder.mutation<
      LoginResponse,
      { token: string; language: string }
    >({
      query: (body) => ({
        url: `api/user/oauth2/keycloak/exchangeToken`,
        method: "POST",
        body,
      }),
    }),
    selfInvite: builder.mutation<
      LoginResponse,
      { token: string; companyId: string; language: string }
    >({
      query: (body) => ({
        url: `api/user/oauth2/keycloak/selfInvite`,
        method: "POST",
        body,
      }),
    }),
    signup: builder.mutation<User, RegisterUser>({
      query: (user) => ({
        url: `api/signup`,
        method: "POST",
        body: user,
      }),
      invalidatesTags: ["User"],
    }),
    requestPasswordReset: builder.mutation<void, { email: string }>({
      query: (body) => ({
        url: `api/user/request-password-reset`,
        method: "POST",
        body,
      }),
    }),
    passwordReset: builder.mutation<void, { key: string; password: string }>({
      query: (body) => ({
        url: `api/user/password-reset`,
        method: "POST",
        body,
      }),
    }),
    setPassword: builder.mutation<void, { password: string }>({
      query: (body) => ({
        url: `api/user/set-password`,
        method: "PATCH",
        body,
      }),
    }),
    validatePasswordReset: builder.query<void, { key: string }>({
      query: (body) => ({
        url: `api/user/validate-password-reset`,
        method: "POST",
        body,
      }),
    }),
    deleteUser: builder.mutation<void, void>({
      query: () => ({
        url: `api/user/me`,
        method: "DELETE",
        invalidatesTags: ["Me"],
      }),
    }),
    activateUser: builder.mutation<void, { key: string }>({
      query: (body) => ({
        url: `api/user/activate`,
        method: "POST",
        body,
      }),
    }),
    me: builder.query<User, void>({
      query: () => ({
        url: `api/user/me`,
      }),
      providesTags: ["Me"],
    }),
    getCompanies: builder.query<Company[], void>({
      query: () => ({
        url: `api/user/companies`,
      }),
      transformResponse(response: Company[]) {
        return response.filter((company) => company.role !== Role.Consumer);
      },
      providesTags: [{ type: "Company", id: "LIST" }],
    }),
    createCompany: builder.mutation<Company, Partial<Company>>({
      query: (company) => ({
        url: `api/companies`,
        method: "POST",
        body: company,
      }),
      invalidatesTags: [{ type: "Company", id: "LIST" }],
    }),
    editCompany: builder.mutation<
      void,
      {
        name?: string;
        currency?: string;
        defaultPaymentId?: string | null;
        region?: string | null;
      }
    >({
      query: (args) => ({
        url: `/api/companies/:companyId`,
        method: "PATCH",
        body: args,
      }),
      invalidatesTags: [
        "Payment",
        "Company",
        "CoffeeMenu",
        "Product",
        "CoffeeMachine",
        "User",
      ],
    }),
    deleteCompany: builder.mutation<void, string>({
      query: () => ({
        url: `/api/companies/:companyId`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Company", id: "LIST" }],
    }),
    transferCompany: builder.mutation<Company, { email: string }>({
      query: ({ email }) => ({
        url: `/api/companies/:companyId/transfer`,
        method: "POST",
        body: {
          email,
        },
      }),
      invalidatesTags: ["Company"],
    }),
    getLocation: builder.query<EntityState<Location>, string>({
      query: (id) => `api/companies/:companyId/locations/${id}`,
      transformResponse(response: Location) {
        return locationsAdapter.addOne(
          locationsAdapter.getInitialState(),
          response
        );
      },
      providesTags: (_, __, id) => [{ type: "Location", id }],
    }),
    getLocations: builder.query<EntityState<Location>, void>({
      query: () => `api/companies/:companyId/locations`,
      transformResponse(response: Location[]) {
        return locationsAdapter.addMany(
          locationsAdapter.getInitialState(),
          response
        );
      },
      providesTags: provideListTags("Location"),
    }),
    createLocation: builder.mutation<EntityState<Location>, Partial<Location>>({
      query: (location) => ({
        url: `api/companies/:companyId/locations`,
        method: "POST",
        body: location,
      }),
      transformResponse(response: Location) {
        return locationsAdapter.addOne(
          locationsAdapter.getInitialState(),
          response
        );
      },
      invalidatesTags: [{ type: "Location", id: "LIST" }],
    }),
    editLocation: builder.mutation<EntityState<Location>, Partial<Location>>({
      query: (location) => ({
        url: `api/companies/:companyId/locations/${location.id}`,
        method: "PATCH",
        body: location,
      }),
      transformResponse(response: Location) {
        return locationsAdapter.addOne(
          locationsAdapter.getInitialState(),
          response
        );
      },
      invalidatesTags: (_, __, { id }) => [
        { type: "Location", id: "LIST" },
        { type: "Location", id },
      ],
    }),
    deleteLocation: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `api/companies/:companyId/locations/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Location", id: "LIST" }],
    }),
    getUserGroups: builder.query<EntityState<UserGroup>, void>({
      query: () => `api/companies/:companyId/user-groups`,
      transformResponse(response: UserGroup[]) {
        return userGroupsAdapter.addMany(
          userGroupsAdapter.getInitialState(),
          response
        );
      },
      providesTags: provideListTags("UserGroup"),
    }),
    createUserGroup: builder.mutation<UserGroup, Partial<UserGroup>>({
      query: (userGroup) => ({
        url: `api/companies/:companyId/user-groups`,
        method: "POST",
        body: userGroup,
      }),
      invalidatesTags: [{ type: "UserGroup", id: "LIST" }],
    }),
    editUserGroup: builder.mutation<UserGroup, Partial<UserGroup>>({
      query: (userGroup) => ({
        url: `api/companies/:companyId/user-groups/${userGroup.id}`,
        method: "PATCH",
        body: userGroup,
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: "UserGroup", id: "LIST" },
        { type: "UserGroup", id },
      ],
    }),
    deleteUserGroup: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `api/companies/:companyId/user-groups/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_, error, id) =>
        !error
          ? [
              { type: "UserGroup", id: "LIST" },
              { type: "UserGroup", id },
              "CoffeeMachine",
            ]
          : [],
    }),
    getUsers: builder.query<
      EntityState<User>,
      Record<string, string | string[]> | void
    >({
      query: (filter) =>
        `/api/companies/:companyId/users?${filter ? createQuery(filter) : ""}`,
      transformResponse(response: User[]) {
        return usersAdapter.addMany(usersAdapter.getInitialState(), response);
      },
      providesTags: provideListTags("User"),
    }),
    getUsersConsumption: builder.query<
      UserConsumptions[],
      Record<string, string | string[]>
    >({
      query: (params) =>
        `/api/companies/:companyId/users/consumption?${createQuery(params)}`,
      providesTags: [{ type: "UserConsumption", id: "LIST" }],
    }),
    verifyUsers: builder.mutation<void, Array<{ id: string; role: Role }>>({
      query: (body) => ({
        url: `/api/companies/:companyId/users/verify`,
        method: "POST",
        body,
      }),
      invalidatesTags: (_, error, verifications) =>
        !error
          ? [
              { type: "User", id: "LIST" },
              ...verifications.map(({ id }) => ({
                type: "User" as "User",
                id,
              })),
            ]
          : [],
    }),
    deleteUserVerifications: builder.mutation<void, string[]>({
      query: (body) => ({
        url: `/api/companies/:companyId/users/verification`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: (_, error, verifications) =>
        !error
          ? [
              { type: "User", id: "LIST" },
              ...verifications.map((id) => ({
                type: "User" as "User",
                id,
              })),
            ]
          : [],
    }),
    exportUsers: builder.mutation<
      string,
      {
        filter?: UserFilter & ConsumptionFilter;
        html?: string;
        type: ExportType;
        language: string;
      }
    >({
      query: (body) => ({
        url: `/api/companies/:companyId/users/export`,
        method: "POST",
        body,
        responseHandler: exportResponseHandler,
      }),
    }),
    exportUserConsumption: builder.mutation<
      string,
      { filter: UserFilter & ConsumptionFilter; language: string; type: string }
    >({
      query: (body) => ({
        url: `/api/companies/:companyId/users/consumption/export`,
        method: "POST",
        body,
        responseHandler: exportResponseHandler,
      }),
    }),
    inviteUsers: builder.mutation<
      void | { errors: Record<string, string> },
      { users: Array<{ email: string; role: string }> }
    >({
      query: (body) => ({
        url: `/api/companies/:companyId/users/invite`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),
    removeCompanyUser: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `api/companies/:companyId/users/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),
    removeCompanyUsers: builder.mutation<void, string[]>({
      query: (ids: string[]) => ({
        url: `api/companies/:companyId/users`,
        method: "DELETE",
        body: ids,
      }),
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),
    editUser: builder.mutation<
      void,
      {
        id: string;
        body: Partial<{
          firstName: string;
          lastName: string;
          address: string;
          email: string;
          preferredLanguage: string;
          userGroups: string[];
          tutorialCompleted: boolean;
        }>;
      }
    >({
      query: (args) => ({
        url: `api/companies/:companyId/users/${args.id}`,
        method: "PATCH",
        body: args.body,
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: "User", id: "LIST" },
        { type: "User", id },
        { type: "Notifications" },
      ],
    }),
    userAddCredit: builder.mutation<
      void,
      { id: string; body: { amount: number } }
    >({
      query: (args) => ({
        url: `api/companies/:companyId/users/${args.id}/credit`,
        method: "POST",
        body: args.body,
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: "User", id: "LIST" },
        { type: "CreditLog", id },
      ],
    }),
    userAddCredits: builder.mutation<
      void,
      Array<{ userId: string; amount: number }>
    >({
      query: (args) => ({
        url: `api/companies/:companyId/users/credit`,
        method: "POST",
        body: args,
      }),
      invalidatesTags: [{ type: "User", id: "LIST" }, "CreditLog"],
    }),
    userGetCreditLogs: builder.query<CreditLog[], { userId: string }>({
      query: ({ userId }) => ({
        url: `api/companies/:companyId/users/${userId}/credit-logs`,
      }),
      providesTags: (_, __, { userId }) => [{ type: "CreditLog", id: userId }],
    }),
    assignUserGroups: builder.mutation<void, Record<string, string[]>>({
      query: (args) => ({
        url: `api/companies/:companyId/users/assign-user-groups`,
        method: "POST",
        body: args,
      }),
      invalidatesTags: (_, error, ids) =>
        !error
          ? [
              { type: "User", id: "LIST" },
              { type: "UserGroup", id: "LIST" },
              ...Object.keys(ids).map((id) => ({ type: "User" as "User", id })),
              ...Object.values(ids)
                .flat()
                .map((id) => ({ type: "UserGroup" as "UserGroup", id })),
            ]
          : [],
    }),
    assignUserRoles: builder.mutation<void, { id: string; role: Role }[]>({
      query: (args) => ({
        url: `/api/companies/:companyId/users/assign-roles`,
        method: "POST",
        body: args,
      }),
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),
    assignUserRole: builder.mutation<
      void,
      { id: string; body: { role: Role } }
    >({
      query: (args) => ({
        url: `/api/companies/:companyId/users/${args.id}/assign-role`,
        method: "POST",
        body: args.body,
      }),
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),
    getCoffeeMachines: builder.query<EntityState<CoffeeMachine>, Filter>({
      query: (body) => ({
        url: `/api/companies/:companyId/coffee-machines?${createQuery(
          body as Record<string, string | string[]>
        )}`,
      }),
      transformResponse(response: CoffeeMachine[]) {
        return coffeeMachinesAdapter.addMany(
          coffeeMachinesAdapter.getInitialState(),
          response
        );
      },
      providesTags: [{ type: "CoffeeMachine", id: "LIST" }],
    }),
    getCoffeeMachine: builder.query<CoffeeMachine, string>({
      query: (machineId) =>
        `/api/companies/:companyId/coffee-machines/${machineId}`,
      providesTags: (_, __, id) => [{ type: "CoffeeMachine", id }],
    }),
    getCoffeeMachineConsumption: builder.query<
      Consumption[],
      {
        machineId: string;
        filter: Record<string, string | string[]>;
      }
    >({
      query: ({ machineId, filter }) =>
        `/api/companies/:companyId/coffee-machines/${machineId}/consumption?${createQuery(
          filter
        )}`,
      providesTags: (_, __, { machineId }) => [
        { type: "CoffeeMachineConsumption", id: machineId },
      ],
    }),
    exportCoffeeMachineConsumption: builder.mutation<
      string,
      {
        machineId: string;
        filter?: ConsumptionFilter;
        language?: string;
        html?: string;
        type: string;
      }
    >({
      query: ({ machineId, ...body }) => ({
        url: `/api/companies/:companyId/coffee-machines/${machineId}/consumption/export`,
        method: "POST",
        body,
        responseHandler: exportResponseHandler,
      }),
    }),
    transferCoffeeMachine: builder.mutation<
      CoffeeMachine,
      { userId: string; id: string }
    >({
      query: ({ id, userId }) => ({
        url: `/api/companies/:companyId/coffee-machines/${id}/transfer`,
        method: "POST",
        body: {
          userId,
        },
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: "CoffeeMachine", id: "LIST" },
        { type: "CoffeeMachine", id },
      ],
    }),
    deleteCoffeeMachine: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `api/companies/:companyId/coffee-machines/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "CoffeeMachine", id: "LIST" }],
    }),
    editCoffeeMachine: builder.mutation<void, EditMachineArgs>({
      query: (args) => ({
        url: `/api/companies/:companyId/coffee-machines/${args.id}`,
        method: "PATCH",
        body: args,
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: "CoffeeMachine", id: "LIST" },
        { type: "CoffeeMachine", id },
        { type: "UserGroup", id: "LIST" },
      ],
    }),
    switchCoffeeMachineMode: builder.mutation<
      void,
      { id: string; mode: PreselectMode }
    >({
      query: ({ id, mode }) => ({
        url: `api/companies/:companyId/coffee-machines/${id}/switch-mode`,
        method: "POST",
        body: { mode },
      }),
      invalidatesTags: (_, error, { id }) =>
        !error
          ? [
              { type: "UserGroup", id: "LIST" },
              { type: "Location", id: "LIST" },
              { type: "CoffeeMenu", id: "LIST" },
              { type: "CoffeeMachine", id: "LIST" },
              { type: "CoffeeMachine", id },
            ]
          : [],
    }),
    getPayments: builder.query<EntityState<Payment>, void>({
      query: () => `/api/companies/:companyId/payment-configs`,
      transformResponse(response: Payment[]) {
        return paymentsAdapter.addMany(
          paymentsAdapter.getInitialState(),
          response
        );
      },
      providesTags: provideListTags("Payment"),
    }),
    getPayment: builder.query<Payment, string>({
      query: (paymentId) =>
        `/api/companies/:companyId/payment-configs/${paymentId}`,
      providesTags: (_, __, id) => [{ type: "Payment", id }],
    }),
    createPayment: builder.mutation<Payment, Omit<Payment, "id">>({
      query: (args) => ({
        url: `/api/companies/:companyId/payment-configs`,
        method: "POST",
        body: args,
      }),
      invalidatesTags: ["Company", { type: "Payment", id: "LIST" }],
    }),
    editPayment: builder.mutation<Payment, Payment>({
      query: (args) => ({
        url: `/api/companies/:companyId/payment-configs/${args.id}`,
        method: "PATCH",
        body: args,
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: "Payment", id: "LIST" },
        { type: "Payment", id },
      ],
    }),
    deletePayment: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `api/companies/:companyId/payment-configs/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Payment", id: "LIST" }],
    }),
    getCurrencies: builder.query<string[], void>({
      query: () => `/api/currencies`,
      providesTags: (_, __) => [{ type: "Currency", id: "LIST" }],
    }),
    getLanguages: builder.query<Record<string, string>, void>({
      query: () => `/api/languages`,
      providesTags: (_, __) => [{ type: "Language", id: "LIST" }],
    }),
    createWidget: builder.mutation<void, { widget: NewWidget }>({
      query: (args) => ({
        url: `/api/companies/:companyId/widgets`,
        method: "POST",
        body: args.widget,
      }),
      invalidatesTags: [{ type: "Widget", id: "LIST" }],
    }),
    editWidget: builder.mutation<void, Widget>({
      query: (widget) => ({
        url: `/api/companies/:companyId/widgets`,
        method: "PATCH",
        body: widget,
        transformResponse(response: Widget) {
          return widgetsAdapter.addOne(
            widgetsAdapter.getInitialState(),
            response
          );
        },
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: "Widget", id: "LIST" },
        { type: "Widget", id },
      ],
    }),
    deleteWidget: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `api/companies/:companyId/widgets/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Widget", id: "LIST" }],
    }),
    getWidgets: builder.query<EntityState<Widget>, void>({
      query: () => ({
        url: `api/companies/:companyId/widgets`,
      }),
      transformResponse(response: Widget[]) {
        return widgetsAdapter.addMany(
          widgetsAdapter.getInitialState(),
          response
        );
      },
      providesTags: [{ type: "Widget", id: "LIST" }],
    }),
    getStatistic: builder.query<Statistic, Filter>({
      query: (body) => ({
        url: `/api/companies/:companyId/statistics?${createQuery(
          body as Record<string, string | string[]>
        )}`,
      }),
      providesTags: [{ type: "Statistic", id: "LIST" }],
    }),
    saveWidgetOrder: builder.mutation<void, SortedWidgetIds>({
      query: (ids) => ({
        url: `/api/companies/:companyId/widgets/order`,
        method: "PATCH",
        body: ids,
      }),
      invalidatesTags: [{ type: "Widget", id: "LIST" }],
    }),
    exportStatistics: builder.mutation<
      string,
      | { html: string; type: "pdf" }
      | { filter: Filter; type: "csv"; language: string }
    >({
      query: (body) => ({
        url: `/api/companies/:companyId/statistics/export`,
        method: "POST",
        body,
        responseHandler: exportResponseHandler,
      }),
    }),
    saveProductOrder: builder.mutation<
      void,
      { coffeeMenuId: string; ids: SortedProductIds }
    >({
      query: (args) => ({
        url: `/api/companies/:companyId/coffee-menus/${args.coffeeMenuId}/products/order`,
        method: "PATCH",
        body: args.ids,
      }),
      invalidatesTags: [{ type: "Product", id: "LIST" }],
    }),
    getCompanyConsumptionStatistics: builder.query<
      any,
      { from?: string; to?: string }
    >({
      query: (params) => ({
        url:
          `/api/companies/:companyId/consumption/statistics?` +
          new URLSearchParams(params).toString(),
        method: "GET",
      }),
    }),
    getCoffeeMenu: builder.query<CoffeeMenu, string>({
      query: (id) => `api/companies/:companyId/coffee-menus/${id}`,
      providesTags: (_, __, id) => [{ type: "CoffeeMenu", id }],
    }),
    getCoffeeMenus: builder.query<EntityState<CoffeeMenu>, void>({
      query: () => `api/companies/:companyId/coffee-menus`,
      transformResponse(response: CoffeeMenu[]) {
        return coffeeMenusAdapter.addMany(
          coffeeMenusAdapter.getInitialState(),
          response
        );
      },
      providesTags: provideListTags("CoffeeMenu"),
    }),
    createCoffeeMenu: builder.mutation<
      void,
      { articleNumber: string; name: string }
    >({
      query: (body) => ({
        url: `/api/companies/:companyId/coffee-menus`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "CoffeeMenu", id: "LIST" }],
    }),
    editCoffeeMenu: builder.mutation<void, { id: string; body: EditMenuArgs }>({
      query: (args) => ({
        url: `/api/companies/:companyId/coffee-menus/${args.id}`,
        method: "PUT",
        body: args.body,
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: "CoffeeMenu", id: "LIST" },
        { type: "CoffeeMenu", id },
      ],
    }),
    deleteCoffeeMenu: builder.mutation<void, string>({
      query: (id) => ({
        url: `api/companies/:companyId/coffee-menus/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_, error, id) =>
        !error
          ? [
              { type: "CoffeeMenu", id: "LIST" },
              { type: "CoffeeMenu", id },
            ]
          : [],
    }),
    shareCoffeeMenu: builder.mutation<{ key: string }, string>({
      query: (id) => ({
        url: `/api/companies/:companyId/coffee-menus/${id}/share`,
        method: "POST",
      }),
    }),
    cloneCoffeeMenu: builder.mutation<
      CoffeeMenu,
      { id?: string; shareKey?: string; name: string }
    >({
      query: (body) => ({
        url: `/api/companies/:companyId/coffee-menus/clone`,
        method: "POST",
        body,
      }),
      invalidatesTags: (_, error, __) => (!error ? ["CoffeeMenu"] : []),
    }),
    getCoffeeMachineModels: builder.query<any, void>({
      query: () => `/api/models`,
      providesTags: (_, __) => [{ type: "Model", id: "LIST" }],
    }),
    getCoffeeMenuProducts: builder.query<EntityState<Product>, string>({
      query: (coffeeMenuId) => `/api/coffee-menus/${coffeeMenuId}/products`,
      transformResponse(response: Product[]) {
        return productsAdapter.setMany(
          productsAdapter.getInitialState(),
          response
        );
      },
      providesTags: [{ type: "Product", id: "LIST" }],
    }),
    getCoffeeMenuAvailableProducts: builder.query<Product[], string>({
      query: (coffeeMenuId) =>
        `/api/companies/:companyId/coffee-menus/${coffeeMenuId}/available-products`,
      providesTags: [{ type: "AvailableProduct", id: "LIST" }],
    }),
    editCoffeeMenuProduct: builder.mutation<void, Product>({
      query: (product) => ({
        url: `/api/companies/:companyId/coffee-menus/${product.coffeeMenuId}/products/${product.id}`,
        method: "PATCH",
        body: product,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        if (!patch.coffeeMenuId) return;

        const patchResult = dispatch(
          api.util.updateQueryData(
            "getCoffeeMenuProducts",
            patch.coffeeMenuId,
            (draft) => {
              const entity = draft?.entities?.[id];
              if (entity) {
                Object.assign(entity, patch);
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (_, __, { id }) => [
        { type: "Product", id: "LIST" },
        { type: "Product", id },
        { type: "CoffeeMenu" },
      ],
    }),
    deleteCoffeeMenuProduct: builder.mutation<
      void,
      { menuId: string; productId: string }
    >({
      query: (args) => ({
        url: `/api/companies/:companyId/coffee-menus/${args.menuId}/products/${args.productId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Product", id: "LIST" }],
    }),
    createCoffeeMenuProduct: builder.mutation<
      void,
      { product: NewProduct; coffeeMenuId: string }
    >({
      query: (args) => ({
        url: `/api/companies/:companyId/coffee-menus/${args.coffeeMenuId}/products`,
        method: "POST",
        body: args.product,
      }),
      invalidatesTags: [{ type: "Product", id: "LIST" }],
    }),
    getCreditTopUps: builder.query<EntityState<CreditTopUp>, void>({
      query: () => `api/companies/:companyId/credit-top-ups`,
      transformResponse(response: CreditTopUp[]) {
        return creditTopUpsAdapter.addMany(
          creditTopUpsAdapter.getInitialState(),
          response
        );
      },
      providesTags: provideListTags("CreditTopUp"),
    }),
    getCreditTopUp: builder.query<CreditTopUp, string>({
      query: (creditTopUpId) =>
        `/api/companies/:companyId/credit-top-ups/${creditTopUpId}`,
      providesTags: (_, __, id) => [{ type: "CreditTopUp", id }],
    }),
    editCreditTopUp: builder.mutation<EntityState<CreditTopUp>, CreditTopUp>({
      query: (creditTopUp) => ({
        url: `api/companies/:companyId/credit-top-ups/${creditTopUp.id}`,
        method: "PATCH",
        body: creditTopUp,
      }),
      transformResponse(response: CreditTopUp) {
        return creditTopUpsAdapter.addOne(
          creditTopUpsAdapter.getInitialState(),
          response
        );
      },
      invalidatesTags: (_, __, { id }) => [
        { type: "CreditTopUp", id: "LIST" },
        { type: "CreditTopUp", id },
      ],
    }),
    deleteCreditTopUp: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `api/companies/:companyId/credit-top-ups/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_, error, id) =>
        !error
          ? [
              { type: "CreditTopUp", id: "LIST" },
              { type: "CreditTopUp", id },
            ]
          : [],
    }),
    createCreditTopUp: builder.mutation<
      EntityState<CreditTopUp>,
      Omit<CreditTopUp, "id">
    >({
      query: (creditTopUp) => ({
        url: `api/companies/:companyId/credit-top-ups`,
        method: "POST",
        body: creditTopUp,
      }),
      invalidatesTags: [{ type: "CreditTopUp", id: "LIST" }],
    }),
    sendFeedbackForm: builder.mutation<
      null,
      {
        name: string;
        email: string;
        topic: string;
        device: string;
        os: string;
        details: string;
        comment: string;
      }
    >({
      query: (feedback) => ({
        url: `api/companies/:companyId/help/feedback`,
        method: "POST",
        body: asFormData(feedback),
      }),
    }),
    getNotifications: builder.query<Notification[], void>({
      query: () => `api/companies/:companyId/notifications`,
      providesTags: [{ type: "Notifications", id: "LIST" }],
    }),
    getFAQs: builder.query<FAQs, void>({
      query: () => `api/info/faq`,
      providesTags: [{ type: "FAQ", id: "LIST" }],
    }),
    markNotificationsRead: builder.mutation<
      null,
      Array<{ id: string; type: NotificationType }>
    >({
      query: (notifications) => ({
        url: "api/companies/:companyId/notifications/mark-read",
        method: "POST",
        body: { notifications },
      }),
      invalidatesTags: (_, error, id) =>
        !error ? [{ type: "Notifications", id: "LIST" }] : [],
    }),
  }),
});

export const resetApiCache = api.util.resetApiState;

export const useLoginMutation = api.useLoginMutation;
export const useLogoutQuery = api.useLogoutQuery;
export const useSignupMutation = api.useSignupMutation;
export const useActivateUserMutation = api.useActivateUserMutation;
export const useRequestPasswordResetMutation =
  api.useRequestPasswordResetMutation;
export const useSetPasswordMutation = api.useSetPasswordMutation;
export const usePasswordResetMutation = api.usePasswordResetMutation;
export const useValidatePasswordResetQuery = api.useValidatePasswordResetQuery;
export const useCreateLocationMutation = api.useCreateLocationMutation;
export const useEditLocationMutation = api.useEditLocationMutation;
export const useDeleteLocationMutation = api.useDeleteLocationMutation;
export const useGetLocationsQuery = api.useGetLocationsQuery;
export const useCreateUserGroupMutation = api.useCreateUserGroupMutation;
export const useEditUserGroupMutation = api.useEditUserGroupMutation;
export const useDeleteUserGroupMutation = api.useDeleteUserGroupMutation;
export const useGetUserGroupsQuery = api.useGetUserGroupsQuery;
export const useGetUsersConsumptionQuery = api.useGetUsersConsumptionQuery;
export const useMeQuery = api.useMeQuery;
export const useGetCompaniesQuery = api.useGetCompaniesQuery;
export const useCreateCompanyMutation = api.useCreateCompanyMutation;
export const useEditCompanyMutation = api.useEditCompanyMutation;
export const useDeleteCompanyMutation = api.useDeleteCompanyMutation;
export const useTransferCompanyMutation = api.useTransferCompanyMutation;
export const useGetUsersQuery = api.useGetUsersQuery;
export const useVerifyUsersMutation = api.useVerifyUsersMutation;
export const useDeleteUserVerificationsMutation =
  api.useDeleteUserVerificationsMutation;
export const useExportUsersMutation = api.useExportUsersMutation;
export const useExportUserConsumptionMutation =
  api.useExportUserConsumptionMutation;
export const useDeleteUserMutation = api.useDeleteUserMutation;
export const useRemoveCompanyUserMutation = api.useRemoveCompanyUserMutation;
export const useRemoveCompanyUsersMutation = api.useRemoveCompanyUsersMutation;
export const useAssignUserRoleMutation = api.useAssignUserRoleMutation;
export const useAssignUserRolesMutation = api.useAssignUserRolesMutation;
export const useAssignUserGroupsMutation = api.useAssignUserGroupsMutation;
export const useEditUserMutation = api.useEditUserMutation;
export const useUserAddCreditMutation = api.useUserAddCreditMutation;
export const useUserAddCreditsMutation = api.useUserAddCreditsMutation;
export const useUserGetCreditLogsQuery = api.useUserGetCreditLogsQuery;
export const useInviteUsersMutation = api.useInviteUsersMutation;
export const useGetCoffeeMachinesQuery = api.useGetCoffeeMachinesQuery;
export const useGetPaymentQuery = api.useGetPaymentQuery;
export const useGetPaymentsQuery = api.useGetPaymentsQuery;
export const useCreatePaymentMutation = api.useCreatePaymentMutation;
export const useEditPaymentMutation = api.useEditPaymentMutation;
export const useDeletePaymentMutation = api.useDeletePaymentMutation;
export const useGetCoffeeMachineQuery = api.useGetCoffeeMachineQuery;
export const useGetCoffeeMachineConsumptionQuery =
  api.useGetCoffeeMachineConsumptionQuery;
export const useExportCoffeeMachineConsumptionMutation =
  api.useExportCoffeeMachineConsumptionMutation;
export const useDeleteCoffeeMachineMutation =
  api.useDeleteCoffeeMachineMutation;
export const useTransferCoffeeMachineMutation =
  api.useTransferCoffeeMachineMutation;
export const useEditCoffeeMachineMutation = api.useEditCoffeeMachineMutation;
export const useSwitchCoffeeMachineModeMutation =
  api.useSwitchCoffeeMachineModeMutation;
export const useGetCoffeeMenuProductsQuery = api.useGetCoffeeMenuProductsQuery;
export const useGetCoffeeMenuAvailableProductsQuery =
  api.useGetCoffeeMenuAvailableProductsQuery;
export const useDeleteCoffeeMenuProductMutation =
  api.useDeleteCoffeeMenuProductMutation;
export const useEditCoffeeMenuProductMutation =
  api.useEditCoffeeMenuProductMutation;
export const useCreateCoffeeMenuProductMutation =
  api.useCreateCoffeeMenuProductMutation;
export const useEditCoffeeMenuMutation = api.useEditCoffeeMenuMutation;
export const useDeleteCoffeeMenuMutation = api.useDeleteCoffeeMenuMutation;
export const useShareCoffeeMenuMutation = api.useShareCoffeeMenuMutation;
export const useCloneCoffeeMenuMutation = api.useCloneCoffeeMenuMutation;
export const useGetCurrenciesQuery = api.useGetCurrenciesQuery;
export const useGetLanguagesQuery = api.useGetLanguagesQuery;
export const useCreateWidgetMutation = api.useCreateWidgetMutation;
export const useEditWidgetMutation = api.useEditWidgetMutation;
export const useDeleteWidgetMutation = api.useDeleteWidgetMutation;
export const useGetWidgetsQuery = api.useGetWidgetsQuery;
export const useGetStatisticQuery = api.useGetStatisticQuery;
export const useSaveWidgetOrderMutation = api.useSaveWidgetOrderMutation;
export const useExportStatisticsMutation = api.useExportStatisticsMutation;
export const useSaveProductOrderMutation = api.useSaveProductOrderMutation;
export const useGetCompanyConsumptionStatisticsQuery =
  api.useGetCompanyConsumptionStatisticsQuery;
export const useGetCoffeeMenuQuery = api.useGetCoffeeMenuQuery;
export const useGetCoffeeMenusQuery = api.useGetCoffeeMenusQuery;
export const useCreateCoffeeMenuMutation = api.useCreateCoffeeMenuMutation;
export const useGetCoffeeMachineModelsQuery =
  api.useGetCoffeeMachineModelsQuery;
export const useGetCreditTopUpsQuery = api.useGetCreditTopUpsQuery;
export const useGetCreditTopUpQuery = api.useGetCreditTopUpQuery;
export const useDeleteCreditTopUpMutation = api.useDeleteCreditTopUpMutation;
export const useEditCreditTopUpMutation = api.useEditCreditTopUpMutation;
export const useCreateCreditTopUpMutation = api.useCreateCreditTopUpMutation;
export const useSelfInviteMutation = api.useSelfInviteMutation;
export const useExchangeTokenMutation = api.useExchangeTokenMutation;
export const useSendFeedbackFormMutation = api.useSendFeedbackFormMutation;
export const useGetNotificationsQuery = api.useGetNotificationsQuery;
export const useMarkNotificationsReadMutation =
  api.useMarkNotificationsReadMutation;
export const useGetFAQsQuery = api.useGetFAQsQuery;

const selectLocationsCache = api.endpoints.getLocations.select();
export const selectLocation = (id: string) => (state: RootState) =>
  selectLocationsCache(state).data?.entities[id];

const selectUserGroupsCache = api.endpoints.getUserGroups.select();
export const selectUserGroup = (id: string) => (state: RootState) =>
  selectUserGroupsCache(state).data?.entities[id];

const selectPaymentsCache = api.endpoints.getPayments.select();
export const selectPayment = (id: string) => (state: RootState) =>
  selectPaymentsCache(state).data?.entities[id];

export const selectUser = (id: string) => (state: RootState) => {
  const providedBy = state.api.provided.User?.[id];
  if (!providedBy?.length) return undefined;
  const queries = providedBy
    .map(
      (ref) =>
        state.api.queries[ref] as NonNullable<
          (typeof state.api.queries)[string]
        >
    )
    .filter((query) => query);
  const latestTimestamp = Math.max(
    ...queries.map((query) => query.fulfilledTimeStamp ?? -Infinity)
  );
  const latest = queries.find(
    (query) => query.fulfilledTimeStamp === latestTimestamp
  );
  return (latest?.data as EntityState<User>).entities[id];
};

export const selectUsers = (ids: string[]) => (state: RootState) =>
  ids.map((id) => selectUser(id)(state)).filter((user) => user) as User[];

const selectCoffeeMenusCache = api.endpoints.getCoffeeMenus.select();
export const selectCoffeeMenu = (id: string) => (state: RootState) =>
  selectCoffeeMenusCache(state).data?.entities[id];

const selectCoffeeMachineCache = api.endpoints.getCoffeeMachines.select({});
export const selectCoffeeMachine = (id: string) => (state: RootState) =>
  selectCoffeeMachineCache(state).data?.entities[id];

const selectCreditTopUpsCache = api.endpoints.getCreditTopUps.select();
export const selectCreditTopUp = (id: string) => (state: RootState) =>
  selectCreditTopUpsCache(state).data?.entities[id];

export const createSelectProductSelector =
  (menuId: string) => (productId: string) => (state: RootState) =>
    api.endpoints.getCoffeeMenuProducts.select(menuId)(state).data?.entities[
      productId
    ];

const selectWidgetsCache = api.endpoints.getWidgets.select();
export const selectWidget = (id: string) => (state: RootState) =>
  selectWidgetsCache(state).data?.entities[id];
