import {
  Box,
  Grid,
  Paper,
  Stack,
  Typography,
  capitalize,
  styled,
} from "@mui/material";
import {
  GridColDef,
  GridToolbarContainer,
  GridToolbarContainerProps,
  GridToolbarExportContainer,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AggregateTable from "../../../components/AggregateTable";
import Amount from "../../../components/Amount";
import { Head } from "../../../components/DataGrid";
import ExportMenuItem from "../../../components/ExportMenuItem";
import { renderCellExpand } from "../../../components/GridCellExpand";
import { useCoffeeMachineConsumptionExport } from "../../../hooks/coffeeMachineConsumptionExport";
import { ExportType } from "../../../hooks/export";
import { selectCompany } from "../../../state/auth";
import { Consumption, productName } from "../../../state/products";
import {
  useGetCoffeeMachineConsumptionQuery,
  useGetCoffeeMenusQuery,
} from "../../../state/services/api";
import { ConsumptionFilter, UserFilter } from "../../../state/users";
import { getAll } from "../../../utils/api";
import { partition } from "../../../utils/array";
import { formatMoney } from "../../../utils/money";
import { mapToOptions } from "../../../utils/object";
import { ellipsisOverflow } from "../../../utils/string";
import DateRangeFilter from "../../usermanagement/filters/dateRange";
import MultiselectFilter from "../../usermanagement/filters/multiSelect";

export interface MachineConsumptionProps {
  export?: boolean;
  page?: number;
  pageSize?: number;
  machineId?: string;
  filter?: UserFilter & ConsumptionFilter;
}

interface MatchParams {
  machineId: string;
}

function useFilter(filterProp: MachineConsumptionProps["filter"]) {
  const [filter, setFilter] = useState({
    from:
      filterProp?.from !== undefined && filterProp?.from !== null
        ? new Date(filterProp.from)
        : null,
    to:
      filterProp?.to !== undefined && filterProp?.to !== null
        ? new Date(filterProp.to)
        : null,
  } as ConsumptionFilter);

  const { data: allMenus } = useGetCoffeeMenusQuery();

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      menuIds: getAll(allMenus).map(({ id }) => id),
    }));
  }, [allMenus]);

  return [filter, setFilter] as [typeof filter, typeof setFilter];
}

const MachineConsumptionExportWrapper = styled(Box)`
  padding: 4rem;
  width: 595pt;
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;
const MachineConsumptionExport = ({
  filter,
  machineId,
}: {
  filter?: ConsumptionFilter;
  machineId?: string;
}) => {
  // 22 users per page
  const idParts = filter?.productIds ? partition(filter.productIds, 22) : [[]];

  return (
    <MachineConsumptionExportWrapper>
      {idParts.map((idPart) => (
        <MachineConsumption
          key={idPart.join(",")}
          export={true}
          machineId={machineId}
          filter={{
            ...filter,
            productIds: idPart,
          }}
        />
      ))}
    </MachineConsumptionExportWrapper>
  );
};

function Toolbar({
  filter,
  machineId,
  hidden,
  ...other
}: {
  filter: ConsumptionFilter;
  machineId: string;
  hidden: boolean;
} & GridToolbarContainerProps) {
  const csvExport = useCoffeeMachineConsumptionExport(ExportType.CSV, {
    machineId,
    filter,
  });
  const xlsxExport = useCoffeeMachineConsumptionExport(ExportType.XLSX, {
    machineId,
    filter,
  });
  const pdfExport = useCoffeeMachineConsumptionExport(ExportType.PDF, {
    machineId,
    filter,
    Component: MachineConsumptionExport,
    componentProps: { machineId, filter }
  });
  const { t } = useTranslation();

  return (
    <GridToolbarContainer
      sx={{ display: hidden ? "none" : "block" }}
      {...other}
    >
      <GridToolbarExportContainer
        sx={{ width: "auto" }}
        disabled={!filter.productIds?.length}
      >
        <ExportMenuItem
          onClick={async () => {
            await csvExport();
          }}
        >
          {t("coffeeMachine.consumption.exportCSV")}
        </ExportMenuItem>
        <ExportMenuItem
          onClick={async () => {
            await xlsxExport();
          }}
        >
          {t("coffeeMachine.consumption.exportXLSX")}
        </ExportMenuItem>
        <ExportMenuItem
          tooltip={t("usermanagement.export.tooltipPDF")}
          onClick={async () => {
            await pdfExport();
          }}
        >
          {t("usermanagement.export.exportPDF")}
        </ExportMenuItem>
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );
}

function MachineConsumption({
  filter: filterProp,
  export: exportEnabled = false,
  pageSize = 10,
  machineId,
}: MachineConsumptionProps) {
  const { t } = useTranslation();
  const { t: tm } = useTranslation("machine");

  const company = useSelector(selectCompany);

  const { machineId: idFromUrl } = useParams<MatchParams>();

  machineId ??= idFromUrl;

  const { data: allMenus } = useGetCoffeeMenusQuery();

  const [filter, setFilter] = useFilter(filterProp);

  const { data: allConsumption = [] } = useGetCoffeeMachineConsumptionQuery({
    machineId,
    filter: {},
  });

  const { data: consumptions = [] } = useGetCoffeeMachineConsumptionQuery({
    machineId,
    filter: {
      ...Object.fromEntries(
        Object.entries(filter).filter(
          ([key]) => exportEnabled || key !== "productIds"
        )
      ),
      ...(filter.from && { from: filter.from?.toString() }),
      ...(filter.to && { to: filter.to?.toString() }),
    },
  });

  const usedMenus = getAll(allMenus)?.filter((menu) =>
    allConsumption.some(
      (consumption) => menu.id === consumption.product.coffeeMenuId
    )
  );

  const showMenu = !filter.menuIds?.length || filter.menuIds.length > 1;

  const getConsumptionName = (consumption: Consumption) => {
    const showMenu = !filter.menuIds?.length || filter.menuIds.length > 1;
    const name = ellipsisOverflow(productName(tm, consumption.product), 30);

    if (showMenu) {
      return `${consumption.product.coffeeMenu?.name}, ${name}`;
    }

    return name;
  };

  const rows = consumptions
    .filter((consumption) => consumption.currency === company?.currency)
    .filter(
      (consumption) =>
        !filterProp?.productIds?.length ||
        filterProp.productIds?.includes(consumption.product.id)
    )
    .map((consumption) => ({
      id: consumption.product.id,
      amount:
        typeof consumption.amount === "string"
          ? parseFloat(consumption.amount)
          : consumption.amount,
      price: consumption.price,
      name: getConsumptionName(consumption),
    }));

  const aggregateRows = useMemo(
    () => [
      {
        id: "## all ##",
        name: t("coffeeMachine.consumption.all"),
        amount: rows.reduce((total, row) => total + (row?.amount ?? 0), 0),
        price: rows.reduce((total, row) => total + (row?.price ?? 0), 0),
      },
    ],
    [rows, t]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: `${t("coffeeMachine.consumption.productTitle")}${
          showMenu
            ? ` ${t("coffeeMachine.consumption.productTitleSpecification")}`
            : ""
        }`,
        headerClassName: "wrap-header",
        cellClassName: "wrap",
        align: "left",
        headerAlign: "left",
        renderCell: renderCellExpand,
        minWidth: 140,
        flex: 1,
      },
      {
        field: "amount",
        headerName: `${t("coffeeMachine.consumption.amount")}`,
        headerClassName: "wrap-header",
        align: "center",
        headerAlign: "center",
        width: 140,
      },
      {
        field: "price",
        headerName: `${t("coffeeMachine.consumption.totalPrice")}`,
        headerClassName: "wrap-header",
        align: "center",
        headerAlign: "center",
        width: 140,
        valueGetter: (params: GridValueGetterParams) =>
          formatMoney(params.row.price, company?.currency ?? "CHF"),
      },
    ],
    [company?.currency, showMenu, t]
  );

  if (exportEnabled) {
    return (
      <AggregateTable
        pageSize={pageSize}
        export={!!exportEnabled}
        filter={filter}
        rows={rows}
        aggregateRows={aggregateRows}
        columns={columns}
        Toolbar={Toolbar}
        selectionModel={filter?.productIds ?? []}
        componentsProps={{
          toolbar: {
            hidden: true,
            filter,
            machineId,
          },
        }}
      />
    );
  }

  return (
    <Grid item xs={12}>
      <Paper>
        <Stack>
          <Stack py={10} pl={2} borderBottom="1px solid #E0E2E7">
            <Amount
              amount={rows?.length}
              text={t("coffeeMachine.consumption.numberOfConsumptions")}
            />
          </Stack>
          <Head>
            <Typography variant="h2" mb={5}>
              {capitalize(t("filter"))}
            </Typography>
            <Stack
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
              flexWrap="wrap"
              rowGap={4}
              columnGap={4}
            >
              <Stack
                flex={1}
                flexDirection={{ lg: "row" }}
                rowGap={4}
                columnGap={4}
                justifyContent="flex-end"
              >
                <Box
                  display="grid"
                  flex={1}
                  gap={3}
                  gridTemplateColumns="repeat(auto-fill, minmax(14rem, 1fr))"
                  sx={{ placeContent: "start" }}
                >
                  <MultiselectFilter
                    label={t("usermanagement.filter.coffeeMenu")}
                    options={mapToOptions(usedMenus, (menu) => menu.name)}
                    setValues={(ids) =>
                      ids && setFilter((f) => ({ ...f, menuIds: ids }))
                    }
                    values={filter.menuIds ?? []}
                  />

                  <DateRangeFilter
                    from={filter.from ?? null}
                    to={filter.to ?? null}
                    setFrom={(from) =>
                      setFilter((f) => ({ ...f, from: from ?? undefined }))
                    }
                    setTo={(to) =>
                      setFilter((f) => ({ ...f, to: to ?? undefined }))
                    }
                    timezone={company?.timezone ?? "Etc/UTC"}
                  />
                </Box>
              </Stack>
            </Stack>
          </Head>
          <Stack flexDirection="row" alignItems="flex-start">
            <AggregateTable
              pageSize={pageSize}
              export={!!exportEnabled}
              filter={filter}
              rows={rows}
              aggregateRows={aggregateRows}
              columns={columns}
              Toolbar={Toolbar}
              selectionModel={filter?.productIds ?? []}
              onSelectionModelChange={(selectionModel) =>
                setFilter?.((f) => ({
                  ...f,
                  productIds: selectionModel as string[],
                }))
              }
              componentsProps={{
                toolbar: {
                  filter,
                  machineId,
                },
              }}
            />
          </Stack>
        </Stack>
      </Paper>
    </Grid>
  );
}

export default MachineConsumption;
