import { Card, Grid, Typography } from "@mui/material";
import { useGetFAQsQuery } from "../../state/services/api";
import { useTranslation } from "react-i18next";
import FAQs from "../../components/faq/FAQs";

const FAQ = () => {
  const { t } = useTranslation();

  const { data, isError } = useGetFAQsQuery();

  return (
    <Grid item xs={12}>
      {data?.questions?.length === 0 || isError ? (
        <Card sx={{ p: 2 }}>
          <Typography fontWeight={"bold"}>{t("help.faq.noEntries")}</Typography>
        </Card>
      ) : (
        data?.questions && <FAQs questions={data!.questions} />
      )}
    </Grid>
  );
};

export default FAQ;
