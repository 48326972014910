import { useEditUserMutation, api } from "../state/services/api";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useDispatch, useSelector } from "../state/hooks";
import { selectCurrentUser } from "../state/auth";
import { CoffeeMachine } from "../state/coffe-machines";

export const useExtendedUserEdit = (
  options?: Parameters<typeof useEditUserMutation>[0]
) => {
  const [editUser, extra] = useEditUserMutation(options);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const user = useSelector(selectCurrentUser);

  const editCallback = useCallback(
    async (userData: Parameters<typeof api.endpoints.editUser.initiate>[0]) => {
      await editUser(userData).unwrap();
      if (userData?.id === user?.id) {
        dispatch(api.util.invalidateTags(["Me", "FAQ"]));
        if (userData?.body?.preferredLanguage) {
          i18n.changeLanguage(userData.body.preferredLanguage);
        }
      }
    },
    [dispatch, editUser, i18n, user]
  );

  return [editCallback, extra] as [typeof editCallback, typeof extra];
};

export const useIsCoffeeMachineOwner = (machine?: CoffeeMachine) => {
  const user = useSelector(selectCurrentUser);
  return machine && machine.createdByUserId === user?.id;
}
